import { Link } from "react-router-dom";

const PropertyTable = ({ page, setPage, data }) => {
  return (
    <>
      <div className="overflow-auto w-full md:overflow-none">
        <table className="table-auto min-w-max w-full mt-4 border shadow-sm border-slate-300">
          <thead className="bg-[#F9FAFC] text-[#5a5a5b] border-b border-slate-300 text-left">
            <tr>
              <th className="p-3 font-medium text-sm">S.No</th>
              <th className="p-3 font-medium text-sm">Id</th>
              <th className="p-3 font-medium text-sm">Owner</th>
              <th className="p-3 font-medium text-sm">State</th>
              <th className="p-3 font-medium text-sm">District</th>
              <th className="p-3 font-medium text-sm">Tehsil</th>
              <th className="p-3 font-medium text-sm">Buy Count</th>
              <th className="p-3 font-medium text-sm">Khatiyan</th>
              <th className="p-3 font-medium text-sm">Jamabandi</th>
              <th className="p-3 font-medium text-sm">Missing Docs</th>
              <th className="p-3 font-medium text-sm">Action</th>
            </tr>
          </thead>
          <tbody>
            {data.length > 0 ? (
              data.slice(page * 15 - 15, page * 15).map((data, index) => (
                <tr
                  key={data._id}
                  className="bg-white border-b border-slate-300 align-middle h-auto"
                >
                  <td className="p-3 text-xs md:text-sm font-medium">
                    {(page - 1) * 15 + index + 1}
                  </td>
                  <td className="p-3 text-xs md:text-sm capitalize">
                    {data._id}
                  </td>
                  <td className="p-3 text-xs md:text-sm capitalize">
                    {data.name}
                  </td>
                  <td
                    className={`p-3 text-xs md:text-sm capitalize ${
                      data?.state.length < 1 && "text-red-500"
                    }`}
                  >
                    {data?.state.length > 0 ? data?.state : "NA"}
                  </td>
                  <td
                    className={`p-3 text-xs md:text-sm capitalize ${
                      data?.district.length < 1 && "text-red-500"
                    }`}
                  >
                    {data?.district.length > 0 ? data?.district : "NA"}
                  </td>
                  <td
                    className={`p-3 text-xs md:text-sm capitalize ${
                      data?.mandal.length < 1 && "text-red-500"
                    }`}
                  >
                    {data?.mandal.length > 0 ? data?.mandal : "NA"}
                  </td>
                  <td className="p-3 text-xs md:text-sm capitalize">
                    {data.buyCount}
                  </td>
                  <td
                    className={`p-3 text-xs md:text-sm ${
                      data.recordOfRights ? "text-green-500" : "text-red-500"
                    }`}
                  >
                    {data.recordOfRights ? "Yes" : "NA"}
                  </td>
                  <td
                    className={`p-3 text-xs md:text-sm ${
                      data.landRevenueRecord ? "text-green-500" : "text-red-500"
                    }`}
                  >
                    {data.landRevenueRecord ? "Yes" : "NA"}
                  </td>
                  <td className="p-3 text-xs md:text-sm">{data.missingDoc}</td>
                  <td className="p-3 text-xs md:text-sm">
                    <Link to={`/property/${data.propertyId}`}>
                      <i
                        className="fa-solid fa-eye text-green-500 cursor-pointer mx-1"
                        title="View Property"
                      ></i>
                    </Link>
                    <i
                      class="fa-solid fa-ban text-red-500 cursor-pointer mx-1"
                      title="Suspend Property"
                    ></i>
                  </td>
                </tr>
              ))
            ) : (
              <tr className="bg-white text-center">
                <td colSpan={11} className="p-3 text-xs md:text-sm">
                  No Properties Found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {data.length > 0 && (
        <div className="flex items-center justify-center mt-7">
          <button
            className="px-2 py-1 mx-1 text-sm bg-black text-white rounded-md"
            disabled={page < 2}
            onClick={() => setPage(page - 1)}
          >
            Prev
          </button>
          <span>
            {page}/{Math.ceil(data.length / 15)}
          </span>
          <button
            className="px-2 py-1 mx-1 text-sm bg-black text-white rounded-md"
            disabled={page >= Math.ceil(data.length / 15)}
            onClick={() => setPage(page + 1)}
          >
            Next
          </button>
        </div>
      )}
    </>
  );
};

export default PropertyTable;
