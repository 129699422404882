import React from "react";
import { Link, useNavigate } from "react-router-dom";
function Success() {
  const navigate = useNavigate();
  const PropertyId = localStorage.getItem("property_id");
  const handaleBackToProperty = () => {
    navigate(`/property/${PropertyId}`);
  };
  return (
    <div className="">
      <div className="success-content flex flex-col shadow-2xl justify-center items-center md:mx-[25%] md:h-screen">
        <i class="fa-solid fa-circle-check text-5xl text-green-700 mt-2"></i>
        <h1 className="text-2xl text-center font-medium md:w-[40%] md:text-2xl mt-2">
          Your payment was successful
        </h1>
        <p className="text-center mt-3 md:text-lg md:px-5">
          Thank you for your recent payment! We truly appreciate your trust in
          Plotts as your platform for finding the perfect property.
        </p>

        <button
          className="bg-green-950 rounded-full px-4 py-2 text-white font-medium my-5"
          onClick={() => {
            handaleBackToProperty();
          }}
        >
          Back To Property
        </button>
      </div>
    </div>
  );
}

export default Success;
