import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios from "axios";
import land from "../Components/assets/8.png";
import { ForgotPasswordModal } from "../Components/Model";
import { toast } from "react-toastify";
import { useLoaderContext } from "../Context/LoaderContext";
function LoginSignup(props) {
  const { loader, setLoader } = useLoaderContext();
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [ErrorMessage, setErrorMessage] = useState({});
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const submit = (details) => {
    const { email, password } = details;

    setLoader(true);
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/user/login`,
        {
          email,
          password,
        },
        { withCredentials: true }
      )
      .then((response) => {
        if (response.data.statusCode === 200) {
          localStorage.setItem("status", "Log Out");
          localStorage.setItem("role", response.data.data.details.role);
          localStorage.setItem("id", response.data.data.details._id);
          localStorage.setItem("token", response.data.data.token);
          setLoader(false);
          toast.success("Login Success !!");
          if (response.data.data.details.role === "buyer") {
            navigate("/properties");
          } else {
            navigate("/myproperties");
          }
        } else {
        }
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          toast.error("Invalid Credentials !!");
          setLoader(false);
        } else {
          toast.error("Server Error !!");
          setLoader(false);
        }
      });
  };

  return (
    <div className="grid grid-cols-6">
      <ForgotPasswordModal
        open={openModal}
        onClose={() => setOpenModal(false)}
      />
      <div className="hidden lg:block col-span-4 w-full">
        <img className="h-full" src={land} alt="" />
      </div>
      <div className="col-span-6 lg:col-span-2 w-full">
        <form onSubmit={handleSubmit(submit)}>
          <div className="sm:w-10/12 md:w-6/12 lg:w-full flex mx-auto">
            <div className="bg-white w-full ps-4 pe-4">
              <div className="flex justify-center">
                <h1 className="mt-4 text-green-950 font-bold text-3xl">
                  Login
                </h1>
              </div>
              <div className="flex flex-col mt-8 gap-3">
                <div className="flex gap-5 items-center bg-slate-300 p-2 rounded-md w-full focus:border-blue-500">
                  <i className="fa-solid fa-envelope ps-2"></i>
                  <input
                    required
                    placeholder="Email"
                    type="email"
                    className="bg-slate-300 w-full"
                    {...register("email", {
                      required: "Email is required",
                      minLength: {
                        value: 2,
                        message: "Min length is 2",
                      },
                    })}
                  />
                </div>
                <p className="text-orange-500">{errors.email?.message}</p>

                {/* password */}
                <div className="flex gap-5 items-center bg-slate-300 p-2 rounded-md w-full focus:border-blue-500">
                  <i className="fa-solid fa-lock ps-2"></i>
                  <input
                    required
                    placeholder=" Password"
                    type="password"
                    className="bg-slate-300 w-full"
                    {...register("password", {
                      required: "Password is required",
                      minLength: {
                        value: 2,
                        message: "Min length is 2",
                      },
                    })}
                  />
                </div>
                <p className="text-orange-500">{errors.password?.message}</p>

                <div className="flex gap-5 justify-center items-center p-2 rounded-md w-full">
                  {loader ? (
                    <>
                      {" "}
                      <div className="w-8 h-8 border-4 border-green-500 border-t-transparent border-solid rounded-full animate-spin"></div>
                    </>
                  ) : (
                    <>
                      <button className="w-full rounded-2xl py-2 text-white font-bold bg-green-950 hover:bg-green-800">
                        Login
                      </button>
                    </>
                  )}
                </div>
                <div className="flex justify-between items-center p-2  rounded-md w-full">
                  <p
                    className="text-blue-700 cursor-pointer"
                    onClick={() => setOpenModal(true)}
                  >
                    Forgot Password?
                  </p>
                  {ErrorMessage.message && (
                    <p className="text-red-500 text-start -mt-6">
                      {ErrorMessage.message}
                    </p>
                  )}
                <div classname='flex'>
                <p>New to plotts? <Link to="/signup" class='text-yellow-500 font-bold underline'>
                     Create an account.
                  </Link></p>
                </div>  
                </div>
                
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default LoginSignup;
