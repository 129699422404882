import { Tabs } from "flowbite-react";

export default function Tab({ user, property }) {
  return (
    <div className="md:ms-16 ">
      <Tabs>
        <Tabs.Item
          title={
            <span className="md:text-lg  bg-yellow-500 text-black py-2 rounded-xl px-2 ">
              Contact Details
            </span>
          }
          // className="text-slate-500 text-lg "
        >
          <div className="px-2">
            {user.length > 0 ? (
              <>
                <div className="mb-5">
                  <div className="flex">
                    <p className="text-slate-500">Name: </p>
                    <p className="ms-3 capitalize">{user[0].name}</p>
                  </div>
                  <div className="flex">
                    <p className="text-slate-500">Phone: </p>
                    <p className="ms-3">{user[0].phone}</p>
                  </div>
                </div>
              </>
            ) : null}
          </div>
        </Tabs.Item>
        <Tabs.Item
          title={
            <span className="md:text-lg  bg-yellow-500 text-black py-2 rounded-xl px-2">
              Images
            </span>
          }
          className="text-slate-500 text-lg "
        >
          <div className="flex ps-2">
            {property?.property_images?.map((image) => (
              <div className="me-5 relative group mt-2 " key={image._id}>
                <a
                  href={`${image.file_path}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="ps-3"
                >
                  <img
                    className="w-36 h-[40px] md:w-24 md:h-[80px]"
                    src={`${image.file_path}`}
                    alt={image.file_name}
                  />
                </a>
              </div>
            ))}
          </div>
        </Tabs.Item>
        <Tabs.Item
          title={
            <span className="md:text-lg  bg-yellow-500 text-black py-2 rounded-xl px-2">
              Documents
            </span>
          }
          className="text-slate-500 text-lg "
        >
          <div className=" flex flex-col md:w-3/12">
            {/* Lagan Receipt */}
            {property.lagan_receipt && (
              <a
                href={`${property.lagan_receipt.file_path}`}
                target="_blank"
                rel="noopener noreferrer"
                className=""
              >
                <div className="flex mt-4 shadow-lg p-2 rounded-lg bg-slate-300 justify-center ">
                  <p className="font-bold">Lagan Receipt</p>
                </div>
              </a>
            )}

            {/* Sale Deed */}
            {property.sale_deed && (
              <a
                href={`${property.sale_deed.file_path}`}
                target="_blank"
                rel="noopener noreferrer"
                className=""
              >
                <div className="flex mt-4 shadow-lg p-2 rounded-lg bg-slate-300 justify-center ">
                  <p className="font-bold">Sale Deed</p>
                </div>
              </a>
            )}

            {/* Land Revenue Record */}
            {property.land_revenue_record && (
              <a
                href={`${property.land_revenue_record.file_path}`}
                target="_blank"
                rel="noopener noreferrer"
                className=""
              >
                <div className="flex mt-4 shadow-lg p-2 rounded-lg bg-slate-300 justify-center ">
                  <p className="font-bold">Land Revenue Records</p>
                </div>
              </a>
            )}

            {/* Land Map */}
            {property.land_map && (
              <a
                href={`${property.land_map.file_path}`}
                target="_blank"
                rel="noopener noreferrer"
                className=""
              >
                <div className="flex mt-4 shadow-lg p-2 rounded-lg bg-slate-300 justify-center ">
                  <p className="font-bold">Land Map</p>
                </div>
              </a>
            )}

            {/* Partition Deed */}
            {property.partition_deed && (
              <a
                href={`${property.partition_deed.file_path}`}
                target="_blank"
                rel="noopener noreferrer"
                className=""
              >
                <div className="flex mt-4 shadow-lg p-2 rounded-lg bg-slate-300 justify-center ">
                  <p className="font-bold">Partition Deed</p>
                </div>
              </a>
            )}

            {/* Mutation */}
            {property.mutation && (
              <a
                href={`${property.mutation.file_path}`}
                target="_blank"
                rel="noopener noreferrer"
                className=""
              >
                <div className="flex mt-4 shadow-lg p-2 rounded-lg bg-slate-300 justify-center ">
                  <p className="font-bold">Mutation</p>
                </div>
              </a>
            )}
          </div>
        </Tabs.Item>
      </Tabs>
    </div>
  );
}
