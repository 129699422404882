import React from "react";

const Terms = () => {
  return (
    <div className="p-7 space-y-3">
      <h1 className="font-semibold text-xl">Terms and Conditions </h1>
      <p>
        These Terms and Conditions ("Agreement") govern your use of the services
        provided by plotts.in ("Platform"), a platform that connects buyers and
        sellers of plots and properties. By accessing or using the Platform, you
        agree to be bound by these terms.This trade name is operated by Bipin
        Kumar{" "}
      </p>
      <p>
        1. Acceptance of Terms By accessing the Platform, you agree to comply
        with and be bound by these Terms and Conditions and any applicable laws.
        If you do not agree with these terms, you should not use the Platform.{" "}
      </p>
      <p>
        2. Services Provided The Platform offers users the ability to view and
        purchase plots of land and property listings. Users can access details
        about a property for a small fee, which provides the contact information
        of the owner or agent and basic land documents.
      </p>
      <p>3. Charges and Payments </p>
      <ul>
        <li>
          There is a one-time charge for viewing the details of a property
          listing. This charge is non-refundable, except under the circumstances
          outlined in our Refund Policy.{" "}
        </li>
        <li>
          Payments must be made using the methods provided on the Platform.{" "}
        </li>
      </ul>
      <p>4. User Obligations </p>
      <ol>
        <li>You must be at least 18 years of age to use the Platform. </li>
        <li>
          You agree to provide accurate and current information when listing or
          purchasing properties.{" "}
        </li>
        <li>
          You are responsible for ensuring that any property you list on the
          Platform complies with local laws and regulations.{" "}
        </li>
      </ol>
      <p>5. Property Listings </p>
      <ol>
        <li>
          The Platform does not verify the accuracy of the land documents or
          property details submitted by sellers. Buyers are encouraged to
          conduct their due diligence before making a purchase.
        </li>
        <li>
          The Platform does not verify the accuracy of the land documents or
          property details submitted by sellers. Buyers are encouraged to
          conduct their due diligence before making a purchase.
        </li>
      </ol>
      <p>6. Refund Policy </p>
      <p>
        Charges for viewing property details are non-refundable, except in cases
        where incorrect information has been provided. Please refer to our
        Refund Policy for more information.{" "}
      </p>
      <p>7. Intellectual Property</p>
      <p>
        All content and materials on the Platform, including logos, text,
        images, and software, are the property of plots.in. You may not
        reproduce, distribute, or use any content from the Platform without
        prior written permission.{" "}
      </p>
      <p>8. Limitation of Liability </p>
      <p>
        Plots.in is not responsible for any loss, damage, or harm arising from
        transactions conducted through the Platform. The Platform serves solely
        as a facilitator between buyers and sellers.{" "}
      </p>
      <p>
        9. Privacy Your use of the Platform is also governed by our Privacy
        Policy, which outlines how we collect, use, and protect your personal
        information.{" "}
      </p>
      <p>
        10. Modifications Plotts.in reserves the right to modify or update these
        Terms and Conditions at any time. Users will be notified of any
        significant changes via email or a notice on the Platform.{" "}
      </p>
      <p>
        11. Termination We reserve the right to terminate or suspend your access
        to the Platform if you violate these Terms and Conditions or engage in
        illegal activities.
      </p>
      <p>
        12. Governing Law This Agreement will be governed by and construed in
        accordance with the laws of India.{" "}
      </p>
      <p>
        13. Contact Information For any questions or concerns regarding these
        Terms and Conditions, please contact us at contact@plotts.in.{" "}
      </p>
      <p>
        By continuing to use the Platform, you acknowledge that you have read,
        understood, and agreed to be bound by these Terms and Conditions.{" "}
      </p>
    </div>
  );
};

export default Terms;
