import { Button, Modal } from "flowbite-react";
import { useForm } from "react-hook-form";

export const PlanForm = ({ open, onClose, prefilled, addPlan, editPlan }) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      planName: prefilled?.plan_type ? prefilled?.plan_type : "",
      planStatus: prefilled?.plan_status ? prefilled?.plan_status : "1",
      propertiesCount: prefilled?.view_count ? prefilled?.view_count : "",
      planAmount: prefilled?.amount ? prefilled?.amount : "",
      discount: prefilled?.discount_status ? prefilled?.discount_status : "0",
      type: prefilled?.discount_type ? prefilled?.discount_type : "flat",
      discountAmount: prefilled?.discount ? prefilled?.discount : 0,
      description: prefilled?.description ? prefilled?.description : "",
    },
  });

  const discountAvailable = watch("discount");

  if (!open) return null;

  const submitForm = (data) => {
    if (prefilled?._id) {
      editPlan(data, prefilled?._id);
    } else addPlan(data);
    onClose();
  };

  return (
    <Modal
      show={open}
      onClose={onClose}
      className="w-[400px] md:w-6/12 !left-1/2 -translate-x-1/2 z-10"
    >
      <div className="flex justify-center items-center">
        <div className="w-full h-full rounded-lg shadow-lg p-3 border-2 border-green-600">
          <h3 className="text-2xl font-semibold text-center">
            {prefilled?._id ? "Update" : "New"} Plan
          </h3>
          <Modal.Body className="px-0">
            <form
              onSubmit={handleSubmit(submitForm)}
              className="grid grid-cols-2 gap-3"
            >
              <div className="col-span-2 sm:col-span-1 flex flex-col gap-y-1">
                <label htmlFor="planName">Plan Name</label>
                <input
                  required
                  type="text"
                  name="planName"
                  id="planName"
                  placeholder="Plan Name"
                  className="bg-slate-300 focus:outline-none p-2 rounded-md w-full focus:border-blue-500"
                  {...register("planName", {
                    required: "Plan name is required",
                  })}
                />
                {errors.planName && (
                  <span className="text-red-500 text-sm">
                    {errors.planName?.message}
                  </span>
                )}
              </div>
              <div className="col-span-2 sm:col-span-1 flex flex-col gap-y-1">
                <label htmlFor="planStatus">Plan Status</label>
                <select
                  name="planStatus"
                  id="planStatus"
                  {...register("planStatus", {
                    required: "Plan status is required",
                  })}
                  className="bg-slate-300 focus:outline-none p-2 rounded-md w-full focus:border-blue-500"
                >
                  <option value="1">Active</option>
                  <option value="0">Inactive</option>
                </select>
                {errors.planStatus && (
                  <span className="text-red-500 text-sm">
                    {errors.planStatus?.message}
                  </span>
                )}
              </div>
              <div className="col-span-2 sm:col-span-1 flex flex-col gap-y-1">
                <label htmlFor="propertiesCount">Properties Accessible</label>
                <input
                  required
                  type="number"
                  name="propertiesCount"
                  id="propertiesCount"
                  placeholder="Properties Count"
                  className="bg-slate-300 focus:outline-none p-2 rounded-md w-full focus:border-blue-500"
                  {...register("propertiesCount", {
                    required: "Properties count is required",
                    validate: (fieldValue) => {
                      return fieldValue > 0 || "Count must be greater than 0";
                    },
                  })}
                />
                {errors.propertiesCount && (
                  <span className="text-red-500 text-sm">
                    {errors.propertiesCount?.message}
                  </span>
                )}
              </div>
              <div className="col-span-2 sm:col-span-1 flex flex-col gap-y-1">
                <label htmlFor="planAmount">Plan Amount</label>
                <input
                  required
                  type="text"
                  name="planAmount"
                  id="planAmount"
                  placeholder="Plan Price"
                  className="bg-slate-300 focus:outline-none p-2 rounded-md w-full focus:border-blue-500"
                  {...register("planAmount", {
                    required: "Plan Amount is required",
                  })}
                />
                {errors.planAmount && (
                  <span className="text-red-500 text-sm">
                    {errors.planAmount?.message}
                  </span>
                )}
              </div>
              <div className="col-span-2 sm:col-span-1 flex flex-col gap-y-1">
                <label htmlFor="discount">Discount</label>
                <select
                  name="discount"
                  id="discount"
                  {...register("discount", {
                    required: "Discount is required",
                  })}
                  className="bg-slate-300 focus:outline-none p-2 rounded-md w-full focus:border-blue-500"
                >
                  <option value="0">No</option>
                  <option value="1">Yes</option>
                </select>
                {errors.discount && (
                  <span className="text-red-500 text-sm">
                    {errors.discount?.message}
                  </span>
                )}
              </div>
              <div className="col-span-2 sm:col-span-1 flex flex-col gap-y-1">
                <label htmlFor="type">Discount Type</label>
                <select
                  name="type"
                  id="type"
                  {...register("type", {
                    required:
                      discountAvailable === "yes"
                        ? "Discount type is required"
                        : false,
                  })}
                  className={`bg-slate-300 focus:outline-none p-2 rounded-md w-full focus:border-blue-500 ${
                    discountAvailable === "no" ? "cursor-not-allowed" : ""
                  }`}
                  disabled={discountAvailable === "no"}
                >
                  <option value="flat">Flat</option>
                  <option value="percentage">Percentage</option>
                </select>
                {errors.type && (
                  <span className="text-red-500 text-sm">
                    {errors.type?.message}
                  </span>
                )}
              </div>
              <div className="col-span-2 sm:col-span-1 flex flex-col gap-y-1">
                <label htmlFor="discountAmount">Discount Amount</label>
                <input
                  required
                  type="number"
                  name="discountAmount"
                  id="discountAmount"
                  placeholder="Discount Amount"
                  className={`bg-slate-300 focus:outline-none p-2 rounded-md w-full focus:border-blue-500 ${
                    discountAvailable === "no" && "cursor-not-allowed"
                  }`}
                  {...register("discountAmount", {
                    required:
                      discountAvailable === "yes"
                        ? "Discount Amount is required"
                        : false,
                  })}
                  disabled={discountAvailable === "no"}
                />
                {errors.discountAmount && (
                  <span className="text-red-500 text-sm">
                    {errors.discountAmount?.message}
                  </span>
                )}
              </div>
              <div className="col-span-2 flex flex-col gap-y-1">
                <label htmlFor="description">Description</label>
                <textarea
                  name="description"
                  id="description"
                  cols="30"
                  rows="2"
                  placeholder="Enter Description"
                  className="bg-slate-300 focus:outline-none p-2 rounded-md w-full focus:border-blue-500"
                  {...register("description", {
                    required: "Plan descripiton is required",
                  })}
                ></textarea>
                {errors.description && (
                  <span className="text-red-500 text-sm">
                    {errors.description?.message}
                  </span>
                )}
              </div>
              <Modal.Footer className="col-span-2 justify-end items-end gap-x-2 p-0">
                <Button type="submit" className="bg-green-950">
                  {prefilled?._id ? "Update" : "Create"}
                </Button>
                <Button className="bg-red-500" onClick={onClose}>
                  Close
                </Button>
              </Modal.Footer>
            </form>
          </Modal.Body>
        </div>
      </div>
    </Modal>
  );
};
