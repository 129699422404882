import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import AdminSidebar from "../../../Components/AdminSidebar";
import UnitsTable from "./UnitsTable";
import { UnitModal } from "../../../Components/UnitModal";
import { RandomId } from "../../../utils/RandomId";

const Units = () => {
  const [units, setUnits] = useState([]);
  const [page, setPage] = useState(1);
  const [editModal, setEditModal] = useState({
    open: false,
    id: null,
    type: "Add",
    unit: "",
  });

  const token = localStorage.getItem("token");
  
  const fetchUnits = useCallback(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/admin/display-units`, {
        headers: {
          "x-token": token,
        },
        withCredentials: true,
      })
      .then((response) => {
        if (response.data.statusCode === 200) {
          setUnits(response.data.data);
        }
      })
      .catch(() => {
        toast.error("Failed to fetch users");
      });
  }, [token]);

  useEffect(() => {
    const fetch = setTimeout(() => {
      fetchUnits();
    });
    return () => {
      clearTimeout(fetch);
    };
  }, [fetchUnits]);

  const deleteUnit = (unitId) => {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/admin/delete-unit`,
        { id: unitId },
        {
          headers: {
            "x-token": token,
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        if (response.data.statusCode === 200) {
          const updatedUnits = units.filter((unit) => unit._id !== unitId);
          setUnits(updatedUnits);
        }
      })
      .catch(() => {
        toast.error("Failed to fetch users");
      });
  };

  const addUnit = (newUnit) => {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/admin/add-unit`,
        { unit: newUnit },
        {
          headers: {
            "x-token": token,
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        if (response.data.statusCode === 200) {
          setUnits((prev) => [...prev, response.data.data]);
        }
      })
      .catch(() => {
        toast.error("Failed to fetch users");
      });
  };

  const editUnit = (unitId, newUnit) => {
    axios
      .put(
        `${process.env.REACT_APP_BACKEND_URL}/admin/edit-unit`,
        { id: unitId, unit: newUnit },
        {
          headers: {
            "x-token": token,
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        if (response.data.statusCode === 200) {
          fetchUnits();
        }
      })
      .catch(() => {
        toast.error("Failed to fetch users");
      });
  };

  const handleEditUnitModal = (id, unit) => {
    setEditModal({ open: true, id, type: "Edit", unit });
  };

  return (
    <div className="p-7 flex flex-col md:flex-row gap-x-7 gap-y-5 lg:gap-y-0">
      <AdminSidebar />
      <div className="w-full">
        <div className="flex flex-col md:flex-row md:items-center justify-between">
          <h3 className="text-2xl font-medium mb-3">Units</h3>
          <button
            className="px-3 py-1 rounded-md bg-[#052E16] text-white"
            onClick={() =>
              setEditModal({ open: true, id: null, type: "Add", unit: "" })
            }
          >
            Add New
          </button>
        </div>
        <UnitsTable
          page={page}
          setPage={setPage}
          data={units}
          deleteUnit={deleteUnit}
          handleEditUnitModal={handleEditUnitModal}
        />
      </div>
      <UnitModal
        key={RandomId()}
        open={editModal.open}
        onClose={() => setEditModal((prev) => ({ ...prev, open: false }))}
        addUnit={addUnit}
        editUnit={editUnit}
        data={editModal}
      />
    </div>
  );
};

export default Units;
