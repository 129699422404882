import React from "react";
import { Link } from "react-router-dom";
import logo from "../Components/assets/logo.png";
const Footer = () => {
  return (
    <footer className="bg-green-950 text-white p-8">
      <div className="container mx-auto md:mx-0 flex flex-col items-center md:flex-row justify-between min-w-full">
        <div className="text-center md:text-left mb-4 md:mb-0">
          <img
            src={logo}
            alt="Company logo"
            className="w-[130px] rounded-full mx-auto md:m-0"
          />
          <p className="mt-3">&copy; 2024 All rights are reserved.</p>
        </div>
        <div className="grid grid-cols-3 lg:grid-cols-6 gap-2 md:gap-x-4 my-3">
          <Link to="/" className="hover:text-gray-400">
            Home
          </Link>
          <Link to="/about" className="hover:text-gray-400">
            About
          </Link>
          <Link to="/properties" className="hover:text-gray-400">
            Properties
          </Link>
          <Link to="/contact" className="hover:text-gray-400">
            Contact
          </Link>
          <Link to="/terms-of-use" className="hover:text-gray-400">
            Terms of Use
          </Link>
          <Link to="/refund-policy" className="hover:text-gray-400">
            Refund Policy
          </Link>
          <Link to="/privacy-policy" className="hover:text-gray-400">
            Privacy Policy
          </Link>
        </div>
        <div className="flex space-x-4 mt-4 md:mt-0">
          <a
            href="https://www.facebook.com/profile.php?id=61564905090848"
            target="_blank"
            rel="noreferrer"
            className="hover:text-gray-400"
          >
            <i className="fab fa-facebook-f"></i>
          </a>
          <a
            href="https://www.instagram.com/plotts.in"
            target="_blank"
            rel="noreferrer"
            className="hover:text-gray-400"
          >
            <i className="fab fa-instagram"></i>
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
