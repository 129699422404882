import ContactForm from "./ContactForm";
import { Link } from "react-router-dom";
function Contact() {
  return (
    <>
      <div className="bg-slate-50  flex justify-center items-center h-44">
        <div className="text-center">
          <h1 className="text-green-950 text-3xl font-bold">Contact</h1>
          <p className="text-green-950 text-xl mt-3">Home - Contact</p>
        </div>
      </div>
      <ContactForm />
      <div className="md:p-24 max-sm:pt-12 max-sm:pb-12">
        <div className="flex max-sm:flex-col bg-green-950 justify-between md:h-[140px] ms-6 me-6 rounded-lg border-2 border-slate-800 outline outline-offset-8 outline-slate-500 p-2">
          <div className="lg:ms-12 flex justify-center items-center">
            <h1 className="text-white text-3xl font-bold">
              Looking to sell or buy a plot?
            </h1>
          </div>
          <div className="flex items-center max-sm:mt-5 max-sm:mb-5  md:me-12 justify-center gap-4">
            <i className="fa-solid fa-phone-volume fa-2xl text-white"></i>
            <div className=" pe-8">
              <p className="text-white font-bold">Phone Number</p>
              <p className="text-white font-bold">+91 7004199456</p>
            </div>
            <Link to="/">
              <button className="hidden md:block bg-yellow-400 hover:bg-yellow-500 text-lg text-black font-bold py-2 px-6 rounded-xl">
                Call Now
              </button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default Contact;
