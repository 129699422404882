import React, { useState } from "react";
import { Button, Modal } from "flowbite-react";

export const TehsilModal = ({ open, onClose, editTehsil, data }) => {
  const [tehsilDetails, setTehsilDetails] = useState(data?.location);
  const [errorMessage, setErrorMessage] = useState("");

  const changeHandler = (e) => {
    setTehsilDetails({
      ...tehsilDetails,
      mandal: e.target.value,
    });
  };
  if (!open) return null;
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!tehsilDetails) return setErrorMessage("Please enter a unit");
    else {
      editTehsil(data.location.mandal, tehsilDetails.mandal);
    }
    onClose();
  };

  return (
    <Modal
      show={open}
      onClose={onClose}
      className="w-11/12 sm:w-10/12 md:w-6/12 lg:w-4/12 !top-1/4 !left-1/2 -translate-x-1/2"
    >
      <div className="flex justify-center items-center">
        <div className="w-full h-full bg-slate-200 rounded-lg shadow-lg">
          <Modal.Header className="pb-3">
            {data.type} District Details
          </Modal.Header>
          <form onSubmit={handleSubmit}>
            <Modal.Body className="pb-3">
              <div className="flex flex-col gap-3">
                <div className="flex items-center">
                  <input
                    placeholder="State"
                    type="text"
                    className="bg-slate-300 focus:outline-none p-2 rounded-md w-full"
                    value={data.location.state}
                    required
                  />
                </div>
                {errorMessage && <p className="text-red-500">{errorMessage}</p>}

                <div className="flex items-center">
                  <input
                    placeholder="District"
                    type="text"
                    className="bg-slate-300 focus:outline-none p-2 rounded-md w-full"
                    value={data.location.district}
                    required
                  />
                </div>
                {errorMessage && <p className="text-red-500">{errorMessage}</p>}
                <div className="flex items-center">
                  <input
                    placeholder="District"
                    type="text"
                    className="bg-slate-300 focus:outline-none p-2 rounded-md w-full"
                    value={tehsilDetails.mandal}
                    onChange={changeHandler}
                    required
                  />
                </div>
                {errorMessage && <p className="text-red-500">{errorMessage}</p>}
              </div>
            </Modal.Body>
            <Modal.Footer className="pt-0 justify-end">
              <Button type="submit" className="bg-green-500">
                Submit
              </Button>
              <Button
                type="button"
                className="bg-red-500 ms-3"
                onClick={onClose}
              >
                Cancel
              </Button>
            </Modal.Footer>
          </form>
        </div>
      </div>
    </Modal>
  );
};
