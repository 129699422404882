import React from "react";

function PrivacyPolicy() {
  return (
    <div className="p-7 space-y-3">
      <h1 className="font-semibold text-xl">Privacy Policy</h1>
      <p>
        At Plotts.in ("we," "us," "our"), we are committed to protecting the
        privacy and security of your personal information. This Privacy Policy
        outlines how we collect, use, and safeguard your data when you use our
        platform to browse, list, or purchase plots of land.
      </p>

      <h1 className="font-bold">1. Information We Collect</h1>
      <p>We may collect the following types of information:</p>
      <ul>
        <li>
          <strong>Personal Information:</strong> This includes details such as
          your name, email address, phone number, and mailing address when you
          register or communicate with us.
        </li>
        <li>
          <strong>Transactional Information:</strong> When you list a property
          or engage in transactions, we collect details about the plot,
          including location, price, and other related information.
        </li>
        <li>
          <strong>Location Data:</strong> We may collect location data to help
          users find plots near their geographical area or based on their
          preferences.
        </li>
        <li>
          <strong>Usage Data:</strong> Information about how you interact with
          our platform, such as browsing history, IP address, and cookies.
        </li>
      </ul>

      <h1 className="font-bold">2. How We Use Your Information</h1>
      <p>We use the information we collect to:</p>
      <ul>
        <li>Facilitate the buying and selling of plots on our platform.</li>
        <li>
          Communicate with you about listings, inquiries, or updates regarding
          our services.
        </li>
        <li>Improve the functionality and user experience of the platform.</li>
        <li>Provide customer support and address user inquiries or issues.</li>
        <li>
          Send promotional materials and special offers (with your consent).
        </li>
        <li>Comply with legal obligations.</li>
      </ul>

      <h1 className="font-bold">3. Data Sharing and Disclosure</h1>
      <p>We may share your information with:</p>
      <ul>
        <li>
          <strong>Third-Party Service Providers:</strong> We use service
          providers to help run certain services on our platform, including
          payment processing, email marketing, and data analytics.
        </li>
        <li>
          <strong>Law Enforcement or Legal Requests:</strong> We may disclose
          information if required by law or if we believe it’s necessary to
          protect our rights, users, or third parties.
        </li>
      </ul>

      <h1 className="font-bold">4. Cookies and Tracking Technologies</h1>
      <p>
        We use cookies and similar tracking technologies to enhance your
        experience on our platform, track usage, and deliver personalized
        content. You can control the use of cookies through your browser
        settings.
      </p>

      <h1 className="font-bold">5. Security of Your Information</h1>
      <p>
        We take reasonable steps to protect your personal information from
        unauthorized access, disclosure, alteration, and destruction. However,
        no online platform is completely secure, and we cannot guarantee
        absolute security.
      </p>

      <h1 className="font-bold">6. Your Rights</h1>
      <p>You have the right to:</p>
      <ul>
        <li>Access the personal data we hold about you.</li>
        <li>Request correction or deletion of your personal data.</li>
        <li>Opt out of receiving marketing communications.</li>
        <li>
          Withdraw consent to the processing of your personal data (where
          applicable).
        </li>
      </ul>
      <p>To exercise these rights, please contact us at contact@plotts.in.</p>

      <h1 className="font-bold">7. Data Retention</h1>
      <p>
        We retain your personal information for as long as necessary to provide
        our services or comply with legal obligations. Once the information is
        no longer required, we will securely delete or anonymize it.
      </p>

      <h1 className="font-bold">8. Third-Party Links</h1>
      <p>
        Our platform may contain links to third-party websites. We are not
        responsible for the privacy practices or content of these external
        sites. We encourage you to review their privacy policies.
      </p>

      <h1 className="font-bold">9. Changes to This Privacy Policy</h1>
      <p>
        We may update this Privacy Policy from time to time. When changes are
        made, we will notify you via email or by posting a notice on our
        platform. The updated policy will be effective as of the "Effective
        Date" stated above.
      </p>

      <h1 className="font-bold">10. Contact Us</h1>
      <p>
        If you have any questions or concerns regarding this Privacy Policy,
        please contact us at:
      </p>
      <p>Email: contact@plotts.in</p>
    </div>
  );
}

export default PrivacyPolicy;
