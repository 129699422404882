import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios from "axios";
import admin from "../../Components/assets/admin.jpg";
import { ForgotPasswordModal } from "../../Components/Model";
import { toast } from "react-toastify";

function AdminLogin() {
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const submit = (details) => {
    const { email, password } = details;

    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/admin/login`,
        {
          email,
          password,
        },
        { withCredentials: true }
      )
      .then((response) => {
        if (response.data.statusCode === 200) {
          localStorage.setItem("status", "Log Out");
          localStorage.setItem("role", response.data.data.details.role);
          localStorage.setItem("id", response.data.data.details._id);
          localStorage.setItem("token", response.data.data.token);
          toast.success("Login Success !!");
          navigate("/admin/users");
        }
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          toast.error("Invalid Credentials !!");
        } else {
          toast.error("Server Error !!");
        }
      });
  };

  return (
    <div className="grid grid-cols-6">
      <ForgotPasswordModal
        open={openModal}
        onClose={() => setOpenModal(false)}
      />
      <div className="hidden lg:block lg:col-span-4 w-full">
        <img className="h-screen" src={admin} alt="" />
      </div>
      <div className="col-span-6 lg:col-span-2 w-full flex items-center justify-center p-4">
        <form
          onSubmit={handleSubmit(submit)}
          className="w-full md:w-6/12 lg:w-full"
        >
          <div className="flex justify-center">
            <h1 className="mt-4 text-green-950 font-bold text-3xl">
              Admin Login
            </h1>
          </div>
          <div className="flex flex-col mt-8 gap-3 md:gap-5">
            <div className="flex gap-5 items-center bg-slate-300 p-2 rounded-md w-full focus:border-blue-500">
              <i className="fa-solid fa-envelope ps-2"></i>
              <input
                required
                placeholder="Email"
                type="email"
                className="bg-slate-300 focus:outline-none"
                {...register("email", {
                  required: "Email is required",
                })}
              />
            </div>
            <p className="text-red-500">{errors.email?.message}</p>
            <div className="flex gap-5 items-center bg-slate-300 p-2 rounded-md w-full focus:border-blue-500">
              <i className="fa-solid fa-lock ps-2"></i>
              <input
                required
                placeholder=" Password"
                type="password"
                className="bg-slate-300 focus:outline-none"
                {...register("password", {
                  required: "Password is required",
                  minLength: {
                    value: 2,
                    message: "Min length is 2",
                  },
                })}
              />
            </div>
            {errors.password?.message && (
              <p className="text-red-500">{errors.password?.message}</p>
            )}
            <span
              className="text-blue-700 cursor-pointer w-fit"
              onClick={() => setOpenModal(true)}
            >
              Forgot Password
            </span>
            <button className="w-full rounded-2xl py-2 font-bold bg-yellow-500 text-black hover:bg-yellow-800">
              Login
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AdminLogin;
