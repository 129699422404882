import React from "react";

const Refund = () => {
  return (
    <div className="p-7 space-y-3">
      <h1 className="font-semibold text-xl">Refund Policy </h1>
      <p>
        At Plotts, we strive to provide accurate and valuable information to
        help you make informed decisions about property investments. Our service
        includes a small charge to view the details of listed properties, which
        covers the property owner or agent’s name, contact details, and basic
        land documents.{" "}
      </p>
      <p>Please note the following refund policy: </p>
      <ol>
        <li>
          Non-Refundable Charge: The charge for accessing property details is
          non-refundable once the information has been provided.{" "}
        </li>
        <li>
          Exceptional Circumstances: Refunds may be considered in exceptional
          cases, such as if the property details provided are incorrect or do
          not match the listing. In such cases, please contact our support team
          within 7 days of purchase with a detailed explanation.{" "}
        </li>
        <li>
          Refund Process: Approved refunds will be processed back to the
          original payment method within 7-10 business days after the request is
          verified.{" "}
        </li>
      </ol>
      <p>
        By using our service, you agree to this refund policy. For any concerns
        or assistance, please reach out to our customer support team.{" "}
      </p>
    </div>
  );
};

export default Refund;
