import React from "react";
import land3 from "../Components/assets/4.jpg";
function Quality() {
  return (
    <div className="m-5 lg:m-24 mt-0">
      <div className="grid lg:grid-cols-2 items-center lg:gap-24">
        <img src={land3} alt="land img" />
        <div className="mt-5">
          <div className="heading">
            <h1 className="text-xl lg:text-4xl text-green-700 font-bold">
              We never compromize with quality work
            </h1>
            <p className="text-slate-500 mt-2">
              At Plotts, we deliver trustworthy, transparent, and efficient
              connections between plot buyers and sellers, ensuring a smooth and
              reliable experience.
            </p>
          </div>
          <div className="grid gap-6 mt-8">
            <div className="">
              <div className="flex gap-2 mb-3">
                <i className="fa-solid fa-sack-dollar fa-xl text-green-950  mt-7 me-3"></i>
                <div>
                  <h1 className="text-lg font-bold">Direct Access</h1>

                  <p className="text-slate-700 ">
                    We offer buyers and sellers direct access to contact details
                    and plot documents, making the process quicker and more
                    transparent.
                  </p>
                </div>
              </div>
              <div className="flex gap-2 mb-3">
                <i className="fa-solid fa-signal fa-xl text-green-950  mt-7 me-3"></i>
                <div>
                  <h1 className="text-lg font-bold">Seamless Communication:</h1>
                  <p className="text-slate-700">
                    We enable direct and secure contact between buyers and
                    sellers, ensuring efficient negotiations without
                    intermediaries.
                  </p>
                </div>
              </div>
              <div className="flex gap-2 mb-3">
                <i className="fa-solid fa-magnifying-glass fa-xl text-green-950  mt-7 me-3"></i>
                <div>
                  <h1 className="text-lg font-bold">Comprehensive Support</h1>
                  <p className="text-slate-700 ">
                    From browsing listings to accessing plot documents, we
                    provide full support throughout the transaction process.
                  </p>
                </div>
              </div>
              <div className="flex gap-2 mb-3">
                <i className="fa-solid fa-lock fa-xl text-green-950  mt-7 me-3"></i>
                <div className="">
                  <h1 className="text-lg font-bold">
                    Customer-Centric Approach
                  </h1>
                  <p className="text-slate-700">
                    We prioritize customer satisfaction by offering easy-to-use
                    services and ensuring that every transaction is smooth,
                    secure, and hassle-free.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Quality;
