import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios from "axios";
function ResetPassword() {
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const submit = async (details) => {
    try {
      const { password } = details;
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/user/reset-password`,
        { password, verifytoken: id },
        {
          withCredentials: true,
        }
      );
      console.log(response);
      if (response.status == 200) {
        navigate("/login");
      } else {
      }
    } catch (err) {
      console.log(err);
      return null;
    }
  };
  return (
    <div className="mt-8">
      <form class="max-w-sm mx-auto" onSubmit={handleSubmit(submit)}>
        <div className="shadow-2xl bg-slate-100 p-4 ">
          <div class="mb-5 text-center">
            <label class="block mb-2 text-3xl font-medium text-gray-900 dark:text-white">
              Reset Your Password
            </label>
          </div>
          <div class="mb-5">
            <label
              for="password"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              New password
            </label>
            <input
              type="password"
              id="password"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              required
              {...register("password", {
                required: "Password is required",
              })}
            />
            <p className="text-orange-500">{errors.password?.message}</p>
          </div>
          <button
            type="submit"
            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
}

export default ResetPassword;
