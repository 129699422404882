import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Footer from "./Components/Footer";
import Navbar from "./Components/Navbar";
import Contact from "./Components/Contact";
import MyProperties from "./Components/MyProperties";
import Blog from "./Components/Blog";
import AllProperties from "./Components/AllProperties";
import LoginSignup from "./Pages/LoginSignup";
import PropertyDetails from "./Pages/PropertyDetails";
import AddProperty from "./Pages/AddProperty";
import EditProperty from "./Pages/EditProperty";
import Home from "./Components/Home/Home";
import ResetPassword from "./Pages/ResetPassword";
import AdminLogin from "./Pages/admin/AdminLogin";
import Users from "./Pages/admin/users/Users";
import AllUsersProperties from "./Pages/admin/properties/Properties";
import About from "./Components/About";
import Terms from "./Components/Terms";
import Refund from "./Components/Refund";
import Units from "./Pages/admin/units/Units";
import Pricing from "./Pages/admin/pricing/Pricing";
import Search from "./Pages/Search";
import Area from "./Pages/admin/area/Area";
import Subscription from "./Pages/Subscription";
import PrivacyPolicy from "./Components/PrivacyPolicy";
import Checkout from "./Pages/Checkout";
import Success from "./Pages/Success";
import Failure from "./Pages/Failure";
import Signup from "./Pages/Signup";

function App() {
  useEffect(() => {
    const loadGoogleTranslateScript = () => {
      return new Promise((resolve, reject) => {
        if (
          document.querySelector(
            'script[src="https://translate.google.com/translate_a/element.js?cb=translateElementInit"]'
          )
        ) {
          resolve();
          return;
        }

        const script = document.createElement("script");
        script.type = "text/javascript";
        script.src =
          "https://translate.google.com/translate_a/element.js?cb=translateElementInit";
        script.async = true;
        script.onload = resolve;
        script.onerror = reject;
        document.body.appendChild(script);
      });
    };

    const initGoogleTranslate = () => {
      window.translateElementInit = () => {
        new window.google.translate.TranslateElement(
          {
            pageLanguage: "en",
            includedLanguages: "en,hi,gu,te,ta,bn,ml,mr,kn,or,",
          },
          "translate_element"
        );
      };
    };

    loadGoogleTranslateScript()
      .then(() => {
        initGoogleTranslate();
      })
      .catch((error) => {
        console.error("Error loading Google Translate script:", error);
      });
  }, []);
  return (
    <div className="flex flex-col min-h-screen">
      <Router>
        <Navbar />
        <div className="flex-grow">
          <Routes>
            <Route
              path="/"
              element={
                <div>
                  <Home />
                </div>
              }
            />
            <Route path="/blog" element={<Blog />} />
            <Route path="/myproperties" element={<MyProperties />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/properties" element={<AllProperties />} />
            <Route path="/login" element={<LoginSignup />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/addProperty" element={<AddProperty />} />
            <Route path="/property/:id" element={<PropertyDetails />} />
            <Route path="/editProperty/:id" element={<EditProperty />} />
            <Route path="/search" element={<Search />} />
            <Route path="/resetPassword/:id" element={<ResetPassword />} />
            <Route path="/terms-of-use" element={<Terms />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/refund-policy" element={<Refund />} />

            {/* Admin */}
            <Route path="/admin" element={<AdminLogin />} />
            <Route path="/admin/users" element={<Users />} />
            <Route path="/admin/properties" element={<AllUsersProperties />} />
            <Route path="/admin/units" element={<Units />} />
            <Route path="/admin/pricing" element={<Pricing />} />
            <Route path="/admin/area" element={<Area />} />
            <Route path="/subscription" element={<Subscription />} />
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/success" element={<Success />} />
            <Route path="/failure" element={<Failure />} />
          </Routes>
        </div>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
