import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import AdminSidebar from "../../../Components/AdminSidebar";
import { PlanForm } from "../../../Model/PlanForm";
import PlanTable from "./PlanTable";
import { RandomId } from "../../../utils/RandomId";

const Pricing = () => {
  const token = localStorage.getItem("token");

  const [planModalData, setPlanModalData] = useState({
    open: false,
    prefilled: null,
  });
  const [plans, setPlans] = useState([]);

  const displayPlans = useCallback(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/plan/display-plans`, {
        headers: {
          "x-token": token,
        },
        withCredentials: true,
      })
      .then((response) => {
        if (response.data.statusCode === 200) {
          setPlans(response.data.data);
        }
      })
      .catch(() => {
        toast.error("Failed to fetch users");
      });
  }, [token]);

  useEffect(() => {
    const display = setTimeout(() => {
      displayPlans();
    });
    return () => {
      clearTimeout(display);
    };
  }, [displayPlans]);

  const addPlan = (data) => {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/plan/add-plan`,
        {
          plan_type: data.planName,
          amount: data.planAmount,
          view_count: data.propertiesCount,
          discount: data.discountAmount,
          plan_status: data.planStatus,
          discount_status: data.discount,
          discount_type: data.type,
          description: data.description,
        },
        {
          headers: {
            "x-token": token,
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        if (response.data.statusCode === 200) {
          toast.success("Plan added successfully");
          displayPlans();
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          toast.error("Failed, Same properties accessible value plan exists");
          return;
        }
        toast.error("Failed to add plan");
      });
  };

  const deletePlan = (id) => {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/plan/delete-plan`,
        { id },
        {
          headers: {
            "x-token": token,
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        if (response.data.statusCode === 200) {
          toast.success("Plan deleted successfully");
          displayPlans();
        }
      })
      .catch(() => {
        toast.error("Failed to delete plan");
      });
  };

  const editPlan = (data, id) => {
    axios
      .put(
        `${process.env.REACT_APP_BACKEND_URL}/plan/edit-plan`,
        {
          id,
          plan_type: data.planName,
          amount: data.planAmount,
          view_count: data.propertiesCount,
          discount: data.discountAmount,
          plan_status: data.planStatus,
          discount_status: data.discount,
          discount_type: data.type,
          description: data.description,
        },
        {
          headers: {
            "x-token": token,
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        console.log(response);
        if (response.data.statusCode === 200) {
          toast.success("Plan updated successfully");
          displayPlans();
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          toast.error("Failed, Same properties accessible value plan exists");
          return;
        }
        toast.error("Failed to update plan");
      });
  };

  return (
    <div className="p-7 flex flex-col lg:flex-row gap-x-7 gap-y-5 lg:gap-y-0">
      <AdminSidebar />
      <div className="w-full">
        <div className="flex items-center justify-between">
          <h3 className="text-2xl font-medium mb-5 ">Pricing</h3>
          <button
            className="px-3 py-1 bg-green-950 hover:bg-green-700 text-white rounded-md"
            onClick={() => setPlanModalData({ open: true, prefilled: null })}
          >
            Create Plan
          </button>
        </div>
        <PlanTable
          data={plans}
          deletePlan={deletePlan}
          setPlanModalData={setPlanModalData}
        />
      </div>
      <PlanForm
        key={RandomId()}
        open={planModalData.open}
        onClose={() => setPlanModalData((prev) => ({ ...prev, open: false }))}
        prefilled={planModalData.prefilled}
        addPlan={addPlan}
        editPlan={editPlan}
      />
    </div>
  );
};

export default Pricing;
