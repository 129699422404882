import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios from "axios";
import land from "../Components/assets/8.png";
import { ForgotPasswordModal } from "../Components/Model";
import { toast } from "react-toastify";
import { useLoaderContext } from "../Context/LoaderContext";
function Signup(props) {
  const { loader, setLoader } = useLoaderContext();
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [ErrorMessage, setErrorMessage] = useState({});
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const submit = (details) => {
    const { name, email, phone, password, role } = details;
    setLoader(true);
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/user/register`,
        {
          name,
          email,
          phone,
          password,
          role,
        },
        { withCredentials: true }
      )
      .then((response) => {
        if (response.data.statusCode == 200) {
          localStorage.setItem("status", "Log Out");
          localStorage.setItem("role", response.data.data.role);
          localStorage.setItem("id", response.data.data._id);
          localStorage.setItem("token", response.data.data.token);
          setLoader(false);
          navigate("/");
        } else {
          toast.warning("User Already Exist");
          setErrorMessage({ message: "User Already Exist" });
          setLoader(false);
        }
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          toast.warning("User Already Exist");
          setErrorMessage({ message: "User Already Exist" });
          setLoader(false);
        } else {
          toast.error("Server Error !!");
          setErrorMessage({
            message: "An error occurred. Please try again.",
          });
          setLoader(false);
        }
      });
  };

  return (
    <div className="grid grid-cols-6">
      <ForgotPasswordModal
        open={openModal}
        onClose={() => setOpenModal(false)}
      />
      <div className="hidden lg:block col-span-4 w-full">
        <img className="h-full" src={land} alt="" />
      </div>
      <div className="col-span-6 lg:col-span-2 w-full">
        <form onSubmit={handleSubmit(submit)}>
          <div className="sm:w-10/12 md:w-6/12 lg:w-full flex mx-auto">
            <div className="bg-white w-full ps-4 pe-4">
              <div className="flex justify-center">
                <h1 className="mt-4 text-green-950 font-bold text-3xl">
                  Sign Up
                </h1>
              </div>
              <div className="flex flex-col mt-8 gap-3">
                {/* Name */}
                <div className="flex gap-5 items-center bg-slate-300 p-2 rounded-md w-full focus:border-blue-500">
                  <i className="fa-solid fa-user ps-2"></i>
                  <input
                    required
                    placeholder="Name"
                    type="text"
                    className="bg-slate-300 w-full"
                    {...register("name", {
                      required: "Name is required",
                      minLength: {
                        value: 2,
                        message: "Min length is 2",
                      },
                    })}
                  />
                </div>
                <p className="text-orange-500">{errors.name?.message}</p>

                {/* Phone */}

                <div className="flex gap-5 items-center bg-slate-300 p-2 rounded-md w-full">
                  <i className="fa-solid fa-phone ps-2"></i>
                  <input
                    required
                    placeholder="Phone"
                    type="text"
                    className="bg-slate-300 w-full"
                    {...register("phone", {
                      required: "Phone is required",
                      minLength: {
                        value: 5,
                        message: "Phone number must be at least 5 characters",
                      },
                      pattern: {
                        value: /^[0-9]+$/,
                        message: "Phone must be a number",
                      },
                    })}
                  />
                </div>
                <p className="text-orange-500">{errors.phone?.message}</p>
                {/* Email */}

                <div className="flex gap-5 items-center bg-slate-300 p-2 rounded-md w-full focus:border-blue-500">
                  <i className="fa-solid fa-envelope ps-2"></i>
                  <input
                    required
                    placeholder="Email"
                    type="email"
                    className="bg-slate-300 w-full"
                    {...register("email", {
                      required: "Email is required",
                      minLength: {
                        value: 2,
                        message: "Min length is 2",
                      },
                    })}
                  />
                </div>
                <p className="text-orange-500">{errors.email?.message}</p>

                {/* password */}
                <div className="flex gap-5 items-center bg-slate-300 p-2 rounded-md w-full focus:border-blue-500">
                  <i className="fa-solid fa-lock ps-2"></i>
                  <input
                    required
                    placeholder=" Password"
                    type="password"
                    className="bg-slate-300 w-full"
                    {...register("password", {
                      required: "Password is required",
                      minLength: {
                        value: 2,
                        message: "Min length is 2",
                      },
                    })}
                  />
                </div>
                <p className="text-orange-500">{errors.password?.message}</p>

                {/* Role */}
                <div className="flex gap-5 items-center bg-slate-300 p-2 rounded-md w-full">
                  <i className="fa-solid fa-user-tag ps-2"></i>
                  <select
                    className="bg-slate-300 w-full"
                    {...register("role", {
                      required: "Role is required",
                    })}
                  >
                    <option value="">Select Role</option>
                    <option value="seller">Seller</option>
                    <option value="buyer">Buyer</option>
                    <option value="agent">Consultant</option>
                  </select>
                </div>
                <p className="text-orange-500">{errors.role?.message}</p>

                <div className="flex gap-5 justify-center items-center p-2 rounded-md w-full">
                  {loader ? (
                    <>
                      {" "}
                      <div className="w-8 h-8 border-4 border-green-500 border-t-transparent border-solid rounded-full animate-spin"></div>
                    </>
                  ) : (
                    <>
                      <button className="w-full rounded-2xl py-2 text-white font-bold bg-green-950 hover:bg-green-800">
                        Create Account
                      </button>
                    </>
                  )}
                </div>
                <div className="flex justify-end items-center p-2 mb-8 rounded-md w-full">
                  <p>Already have an account. </p>
                  <Link to="/login" class='ms-2 text-yellow-500 font-bold underline'>
                      Login Now
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Signup;
