import { useEffect, useState, useRef } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import AdminSidebar from "../../../Components/AdminSidebar";
import { AddStateModal } from "../../../Model/AddStateModal";
import StateTable from "./StateTable";
import DistrictTable from "./DistrictTable";
import TehsilTable from "./TehsilTable";
import { RandomId } from "../../../utils/RandomId";
import { StateModal } from "../../../Components/StateModal";
import { DistrictModal } from "../../../Components/DistrictModal";
import { TehsilModal } from "../../../Components/TehsilModal";
import { DeleteConfirm } from "../../../Model/DeleteConfirm";

const Area = () => {
  const token = localStorage.getItem("token");
  const [areaFile, setAreaFile] = useState(null);
  const [states, setStates] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [tehsil, setTehsil] = useState([]);
  const [page, setPage] = useState(1);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [tableType, setTableType] = useState("state");
  const fileInputRef = useRef(null);
  const [editModal, setEditModal] = useState({
    open: false,
    id: null,
    type: "Add",
    location: null,
  });
  const [deleteModal, setDeleteModal] = useState({
    open: false,
    id: null,
    type: "",
    location: null,
  });

  useEffect(() => {
    const fetchStates = setTimeout(() => {
      axios
        .get(`${process.env.REACT_APP_BACKEND_URL}/state/display-states`, {
          headers: {
            "x-token": token,
          },
          withCredentials: true,
        })
        .then((response) => {
          if (response.data.statusCode === 200) {
            setStates(response.data.data);
          }
        })
        .catch(() => {
          toast.error("Failed to fetch states");
        });
    });
    return () => {
      clearTimeout(fetchStates);
    };
  }, [token]);

  useEffect(() => {
    const fetchStates = setTimeout(() => {
      axios
        .get(
          `${process.env.REACT_APP_BACKEND_URL}/state/display-district-state`,
          {
            headers: {
              "x-token": token,
            },
            withCredentials: true,
          }
        )
        .then((response) => {
          if (response.data.statusCode === 200) {
            setDistricts(response.data.data);
          }
        })
        .catch(() => {
          toast.error("Failed to fetch states");
        });
    });
    return () => {
      clearTimeout(fetchStates);
    };
  }, [token]);

  useEffect(() => {
    const fetchStates = setTimeout(() => {
      axios
        .get(`${process.env.REACT_APP_BACKEND_URL}/state/display-all-states`, {
          headers: {
            "x-token": token,
          },
          withCredentials: true,
        })
        .then((response) => {
          if (response.data.statusCode === 200) {
            setTehsil(response.data.data);
          }
        })
        .catch(() => {
          toast.error("Failed to fetch states");
        });
    });
    return () => {
      clearTimeout(fetchStates);
    };
  }, [token]);

  const deleteArea = (data) => {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/state/delete-area`,
        { data },
        {
          headers: {
            "x-token": token,
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        if (response.data.statusCode === 200) {
          if (data.type == "Tehsil") {
            const updatedTehsil = tehsil.filter(
              (mandal) => mandal.mandal !== data.location.mandal
            );
            setTehsil(updatedTehsil);
          } else if (data.type == "District") {
            const updatedDistrict = districts.filter(
              (district) => district.district !== data.location.district
            );
            setDistricts(updatedDistrict);
          } else {
            const updatedState = states.filter(
              (state) => state.state !== data.location.state
            );
            setStates(updatedState);
          }

          toast.success("Delete Successfully");
        }
      })
      .catch(() => {
        toast.error("Failed to fetch users");
      });
  };

  const editState = (oldState, newState) => {
    axios
      .put(
        `${process.env.REACT_APP_BACKEND_URL}/state/edit-state`,
        { old_state: oldState, new_state: newState },
        {
          headers: {
            "x-token": token,
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        if (response.data.statusCode === 200) {
          toast.success("Edit Successfully");
        }
      })
      .catch(() => {
        toast.error("Failed to fetch users");
      });
  };

  const editDistrict = (oldDistrict, newDistrict) => {
    axios
      .put(
        `${process.env.REACT_APP_BACKEND_URL}/state/edit-district`,
        { district: oldDistrict, newDistrict },
        {
          headers: {
            "x-token": token,
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        if (response.data.statusCode === 200) {
          toast.success("Edit Successfully");
        }
      })
      .catch(() => {
        toast.error("Failed to fetch users");
      });
  };

  const editTehsil = (oldTehsil, newTehsil) => {
    axios
      .put(
        `${process.env.REACT_APP_BACKEND_URL}/state/edit-tehsil`,
        { mandal: oldTehsil, newMandal: newTehsil },
        {
          headers: {
            "x-token": token,
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        if (response.data.statusCode === 200) {
          toast.success("Edit Successfully");
        }
      })
      .catch(() => {
        toast.error("Failed to fetch users");
      });
  };

  const handleEditUnitModal = (location) => {
    setEditModal({ open: true, id: null, type: "Edit", location });
  };

  const handleDeleteModal = (location, type) => {
    setDeleteModal({ open: true, id: null, type, location });
  };

  const handleAreaUpload = () => {
    if (!areaFile) return;

    const formData = new FormData();
    formData.append("file", areaFile);
    axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/state/add-state`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "x-token": token,
        },
        withCredentials: true,
      })
      .then((response) => {
        if (response.data.statusCode === 200) {
          toast.success("Area Details Added");
          setAreaFile(null); // Reset the file state
          fileInputRef.current.value = ""; // Clear the file input
        }
      })
      .catch((err) => {
        toast.error("Server Error");
        console.log(err);
      });
  };

  return (
    <div className="p-7 flex flex-col lg:flex-row gap-x-7 gap-y-5 lg:gap-y-0">
      <AdminSidebar />
      <div className="w-full">
        <div className="flex items-flex justify-between mb-5">
          <h3 className="text-2xl font-medium">Area - {tableType}</h3>
          <div className="flex items-center gap-x-3">
            <button
              onClick={() => setTableType("state")}
              className={`px-3 py-1 ${
                tableType === "state"
                  ? "bg-yellow-500 text-black"
                  : "bg-green-950 hover:bg-green-700 text-white"
              } rounded-md font-medium`}
            >
              States
            </button>
            <button
              onClick={() => setTableType("district")}
              className={`px-3 py-1 ${
                tableType === "district"
                  ? "bg-yellow-500 text-black"
                  : "bg-green-950 hover:bg-green-700 text-white"
              } rounded-md font-medium`}
            >
              Districts
            </button>
            <button
              onClick={() => setTableType("tehsil")}
              className={`px-3 py-1 ${
                tableType === "tehsil"
                  ? "bg-yellow-500 text-black"
                  : "bg-green-950 hover:bg-green-700 text-white"
              } rounded-md font-medium`}
            >
              Tehsils
            </button>
          </div>
        </div>
        <div className="flex flex-col">
          <div>
            <label htmlFor="fileUpload">Import Data</label>
          </div>
          <div className="flex gap-2">
            <input
              type="file"
              accept=".csv"
              name="fileUpload"
              id="fileUpload"
              ref={fileInputRef}
              className="px-3 py-1 rounded-md bg-slate-500 text-white w-fit"
              onChange={(e) =>
                e.target.files.length > 0
                  ? setAreaFile(e.target.files[0])
                  : null
              }
            />
            {areaFile ? (
              <label
                className="w-fit bg-green-950 hover:bg-green-800 text-white font-medium py-2 px-5 rounded-lg text-center cursor-pointer"
                onClick={handleAreaUpload}
              >
                Upload
              </label>
            ) : (
              <label className="w-fit bg-green-950/50 py-2 px-5 rounded-lg text-white font-medium text-center">
                Upload
              </label>
            )}
          </div>
        </div>
        {tableType === "state" ? (
          <>
            <StateTable
              data={states}
              page={page}
              setPage={setPage}
              handleEditUnitModal={handleEditUnitModal}
              handleDeleteModal={handleDeleteModal}
            />
            <StateModal
              key={RandomId()}
              open={editModal.open}
              onClose={() => setEditModal((prev) => ({ ...prev, open: false }))}
              editState={editState}
              data={editModal}
            />
          </>
        ) : tableType === "district" ? (
          <>
            <DistrictTable
              data={districts}
              page={page}
              setPage={setPage}
              handleEditUnitModal={handleEditUnitModal}
              handleDeleteModal={handleDeleteModal}
            />
            <DistrictModal
              key={RandomId()}
              open={editModal.open}
              onClose={() => setEditModal((prev) => ({ ...prev, open: false }))}
              editDistrict={editDistrict}
              data={editModal}
            />
          </>
        ) : (
          <>
            <TehsilTable
              data={tehsil}
              page={page}
              setPage={setPage}
              handleEditUnitModal={handleEditUnitModal}
              handleDeleteModal={handleDeleteModal}
            />
            <TehsilModal
              key={RandomId()}
              open={editModal.open}
              onClose={() => setEditModal((prev) => ({ ...prev, open: false }))}
              editTehsil={editTehsil}
              data={editModal}
            />
          </>
        )}
        <DeleteConfirm
          key={RandomId()}
          open={deleteModal.open}
          onClose={() => setDeleteModal((prev) => ({ ...prev, open: false }))}
          deleteArea={deleteArea}
          data={deleteModal}
        />
      </div>
    </div>
  );
};

export default Area;
