import React, { useState } from "react";
import Hero from "../Hero";
import Properties from "../Properties";
import Banner from "../Banner";
import Contact from "../Contact";
import Choose from "../Choose";
import Quality from "../Quality";
import Buyer from "../Buyer";
import Seller from "../Seller";
import Faq from "../Faq";
import ContactForm from "../ContactForm";
function Home() {
  return (
    <div>
      <Hero />
      <Properties />
      <Choose />
      <Quality />
      {/* <Buyer />
      <Seller /> */}
      <Banner />
      <Faq />
      {/* <Contact /> */}
      <ContactForm />
    </div>
  );
}

export default Home;
