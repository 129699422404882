import "./subscriptionCard.css";

const SubscriptionCard = ({ data, index, handleCheckout }) => {
  return (
    <div
      className={`plan ${
        index === 1 ? "highlight shadow-md" : ""
      } col-span-6 sm:col-span-3 md:col-span-2`}
    >
      {data.discount_status === 1 && (
        <img
          src="/special-offer.png"
          alt="special offer"
          width={100}
          className="special-offer-img"
        />
      )}
      <div className="inner">
        {data.discount_status === 0 ? (
          <p className="pricing">&#8377; {data.final_amount}</p>
        ) : (
          <p className="pricing">
            <span>
              {" "}
              &#8377; <del>{data.amount}</del>
              <sub>/ {data.final_amount}</sub>
            </span>
          </p>
        )}

        <p className="title">{data.plan_type}</p>
        <p className="info">{data.description}</p>
        <ul className="features">
          <li>
            <span className="icon">
              <svg
                height="24"
                width="24"
                viewBox="0 0 24 24"
                xmlns="
  http://www.w3.org/2000/svg"
              >
                <path d="M0 0h24v24H0z" fill="none"></path>
                <path
                  fill="currentColor"
                  d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"
                ></path>
              </svg>
            </span>
            <span>{data.view_count} Plots available for viewing</span>
          </li>
          <li>
            <span className="icon">
              <svg
                height="24"
                width="24"
                viewBox="0 0 24 24"
                xmlns="
  http://www.w3.org/2000/svg"
              >
                <path d="M0 0h24v24H0z" fill="none"></path>
                <path
                  fill="currentColor"
                  d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"
                ></path>
              </svg>
            </span>
            <span>
              &#8377;{Math.floor(data.final_amount / data.view_count)} per plot
            </span>
          </li>
        </ul>
        <div className="action">
          <button className="button" onClick={() => handleCheckout(data)}>
            Choose plan
          </button>
        </div>
      </div>
      {index === 1 && (
        <p className="font-bold text-lg text-green-700 text-center">
          Popular Choice
        </p>
      )}
    </div>
  );
};

export default SubscriptionCard;
