import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { LoginProvider } from "./Context/loginContext";
import { LoaderProvider } from "./Context/LoaderContext"; // Import LoaderProvider
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <LoaderProvider>
      {" "}
      {/* Wrap with LoaderProvider */}
      <LoginProvider>
        <App />
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </LoginProvider>
    </LoaderProvider>
  </React.StrictMode>
);
