import React from "react";
import { Link } from "react-router-dom";

function Failure() {
  return (
    <div className="">
      <div className="success-content flex flex-col shadow-2xl justify-center items-center md:mx-[25%] md:h-screen">
        <i className="fa-solid fa-circle-xmark text-5xl text-red-600 mt-2"></i>
        <h1 className="text-2xl text-center font-medium w-[60%] md:text-2xl mt-2">
          Your payment failed
        </h1>
        <p className="text-center mt-3 md:text-md md:px-5">
          We regret to inform you that your recent payment attempt was
          unsuccessful. There could be various reasons for this, such as a
          network issue or incorrect payment details.
        </p>
        <Link to="/properties">
          <button className="bg-green-950 rounded-full px-4 py-2 text-white font-medium my-5 ">
            Back To Prperties
          </button>
        </Link>
      </div>
    </div>
  );
}

export default Failure;
