import React from "react";
import land3 from "../Components/assets/1.jpg";
import profile from "../Components/assets/Testi1.jpg";
import { Link } from "react-router-dom";
import Slider from "./carousal/Slider";
function Banner() {
  return (
    <>
      <div className="">
        <div className="md:p-24 max-sm:pt-12 max-sm:pb-12">
          <div className="flex max-sm:flex-col bg-green-950 justify-between md:h-[140px] ms-6 me-6 rounded-lg border-2 border-slate-800 outline outline-offset-8 outline-slate-500 p-2">
            <div className="lg:ms-12 flex justify-center items-center">
              <h1 className="text-white text-3xl font-bold">
                Looking to sell or buy a plot?
              </h1>
            </div>
            <div className="flex items-center max-sm:mt-5 max-sm:mb-5  md:me-12 justify-center gap-4">
              <i className="fa-solid fa-phone-volume fa-2xl text-white"></i>
              <div className=" pe-8">
                <p className="text-white font-bold">Phone Number</p>
                <p className="text-white font-bold">+91 7004199456</p>
              </div>
              <Link to="/">
                <button className="hidden md:block bg-yellow-400 hover:bg-yellow-500 text-lg text-black font-bold py-2 px-6 rounded-xl">
                  Call Now
                </button>
              </Link>
            </div>
          </div>
        </div>
        {/* testimonials */}

        <div className="flex justify-center flex-col items-center md:pe-24 md:ps-24 md:pb-24 bg-slate-50 pt-10">
          <div>
            <div className="text-center">
              <h1 className="text-xl lg:text-4xl text-green-700 font-bold">
                What our customers are saying
              </h1>
            </div>
            <div className="grid lg:grid-cols-2 max-sm:grid-flow-row items-center grid-flow-col md:gap-16 max-sm:gap-12 md:mt-16 max-sm:mt-8 max-sm:mb-8">
              <img
                src={land3}
                alt="testimonial hero img"
                className="hidden lg:block rounded-lg"
              />
              <Slider />
              {/* <div className="flex flex-col md:me-16 justify-center max-sm:items-center">
                <div className="">
                  <i className="fa-solid fa-face-grin-stars fa-2xl"></i>
                </div>
                <div className="mt-6 p-2">
                  <p>
                  Finding the perfect plot has never been easier! This platform seamlessly connects buyers and sellers, offering a hassle-free experience with a wide range of properties to choose from. Whether you're looking for a residential or commercial plot, the search process is smooth, efficient, and trustworthy. Highly recommended for anyone looking to invest in land!
                  </p>
                </div>
                <div className="md:mt-7 max-sm:mt-4">
                  <i className="fa-solid fa-star fa-lg text-yellow-400"></i>
                  <i className="fa-solid fa-star fa-lg ps-2 text-yellow-400"></i>
                  <i className="fa-solid fa-star fa-lg ps-2 text-yellow-400"></i>
                  <i className="fa-solid fa-star fa-lg ps-2 text-yellow-400"></i>
                  <i className="fa-solid fa-star fa-lg ps-2 text-yellow-400"></i>
                </div>
                <div className="flex gap-6 mt-6 items-center">
                  <div>
                    <img src={profile} className="w-24 rounded-lg" />
                  </div>
                  <div>
                    <p className="text-xl font-medium">Vikas Raj</p>
                    <p className="text-blue-500 font-medium">Bihta</p>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Banner;
