import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import UserTable from "./UserTable";
import { Link } from "react-router-dom";
import AdminSidebar from "../../../Components/AdminSidebar";

const Users = () => {
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(1);
  const [selectedFilter, setSelectedFilter] = useState("All");
  const token = localStorage.getItem("token");

  useEffect(() => {
    const fetchUsers = setTimeout(() => {
      axios
        .get(`${process.env.REACT_APP_BACKEND_URL}/admin/all-users`, {
          headers: {
            "x-token": token,
          },
          withCredentials: true,
        })
        .then((response) => {
          if (response.data.statusCode === 200) {
            setUsers(response.data.data);
          }
        })
        .catch(() => {
          toast.error("Failed to fetch users");
        });
    });

    return () => {
      clearTimeout(fetchUsers);
    };
  }, [token]);

  return (
    <div className="p-7 flex flex-col lg:flex-row gap-x-7 gap-y-5 lg:gap-y-0">
      <AdminSidebar />
      <div className="w-full">
        <div className="flex flex-col md:flex-row md:items-center justify-between">
          <h3 className="text-2xl font-medium mb-3">{selectedFilter} Users</h3>
          <div className="flex items-center order-1 md:order-2">
            <label
              htmlFor="user-filter"
              className="text-lg font-medium mr-2 mb-0"
            >
              Filter Users :{" "}
            </label>
            <select
              name="user-filter"
              id="user-filter"
              className="border rounded-md px-3 py-1 bg-gray-100 focus-visible: outline-none"
              value={selectedFilter}
              onChange={(e) => setSelectedFilter(e.target.value)}
            >
              <option value="All">All</option>
              <option value="Buyer">Buyer</option>
              <option value="Seller">Seller</option>
              <option value="Agent">Agent</option>
            </select>
          </div>
        </div>
        <UserTable
          page={page}
          setPage={setPage}
          data={users}
          selectedFilter={selectedFilter}
        />
      </div>
    </div>
  );
};

export default Users;
