import { Link } from "react-router-dom";

const AdminSidebar = () => {
  return (
    <div className="shadow-md rounded-lg p-3 h-full bg-slate-100 w-full sm:w-[200px]">
      <h6 className="text-xl font-medium mb-5">Pages</h6>
      <div className="flex flex-col admin-sidebar">
        <Link
          to="/admin/users"
          className="border-b py-2 ps-2 hover:bg-gray-300 hover:rounded-md"
        >
          Users
        </Link>
        <Link
          to="/admin/properties"
          className="border-b py-2 ps-2 hover:bg-gray-300 hover:rounded-md"
        >
          Properties
        </Link>
        <Link
          to="/admin/units"
          className="border-b py-2 ps-2 hover:bg-gray-300 hover:rounded-md"
        >
          Units
        </Link>
        <Link
          to="/admin/pricing"
          className="border-b py-2 ps-2 hover:bg-gray-300 hover:rounded-md"
        >
          Pricing
        </Link>
        <Link
          to="/admin/area"
          className="py-2 ps-2 hover:bg-gray-300 hover:rounded-md"
        >
          Area
        </Link>
      </div>
    </div>
  );
};

export default AdminSidebar;
