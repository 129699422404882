import "./slider.css";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

const testimonials = [
  {
    id: 1,
    name: "Rahul Yadav",
    title: "Bihta",
    quote:
      "अपने प्लॉट को बेचने के लिए मुझे इस वेबसाइट का उपयोग किया और बहुत ही जल्दी अच्छे खरीदार मिले। प्लॉट लिस्ट करना बेहद सरल था। यह प्लेटफार्म सचमुच प्लॉट खरीदने और बेचने वालों के लिए बेहतरीन है।",
    image: "https://files.plotts.in/images/test2.PNG",
    rating: 5,
  },
  {
    id: 2,
    name: " अमन वर्मा",
    title: "राँची",
    quote:
      "मुझे अपने सपनों का प्लॉट ढूंढने में काफी समय से मुश्किल हो रही थी। लेकिन इस वेबसाइट की मदद से मेरा काम बेहद आसान हो गया। यहां प्लॉट की जानकारी स्पष्ट होती है और पूरे प्रॉसेस को समझना भी आसान होता है। मैं अब अपने बजट और जरूरतों के अनुसार सही प्लॉट चुन सका। वेबसाइट पर लिस्टेड प्लॉट्स की वेरायटी और सही लोकेशन की जानकारी ने मुझे बहुत मदद की। अब मैं अपनी प्रॉपर्टी खरीदने के फैसले से पूरी तरह संतुष्ट हूं।",
    image: "https://files.plotts.in/images/test1.jpg",
    rating: 5,
  },
  {
    id: 3,
    name: "रवि कुमार",
    title: "पटना",
    quote:
      "मुझे अपने पुराने प्लॉट को बेचना था और कई जगह कोशिश की लेकिन कोई सफलता नहीं मिली। इस वेबसाइट पर प्लॉट लिस्ट करने के कुछ ही दिनों में मुझे अच्छा रिस्पॉन्स मिला और जल्द ही मैंने एक बेहतर डील फाइनल कर ली। साइट पर लिस्टिंग का प्रोसेस काफी सरल है और टीम भी हमेशा मदद के लिए तैयार रहती है।",
    image: "https://files.plotts.in/images/test3.jpg",
    rating: 5,
  },
  {
    id: 4,
    name: "विकास राज",
    title: "भिलाई",
    quote:
      "इस वेबसाइट के ज़रिए मैंने अपने सपनों का प्लॉट खरीदा। यहाँ हर जानकारी एकदम साफ़ और सटीक दी जाती है। सही लोकेशन, बजट और ज़रूरत के हिसाब से प्लॉट ढूंढना पहले बहुत मुश्किल था, लेकिन इस प्लेटफार्म ने सब आसान बना दिया। मैं इस सेवा से पूरी तरह संतुष्ट हूँ और इसे सबको सलाह देता हूँ।",
    image: "https://files.plotts.in/images/test5.jpg",
    rating: 5,
  },
  {
    id: 5,
    name: "सपना शर्मा",
    title: "भोपाल",
    quote:
      "Finding the perfect plot has never been easier! This platform seamlessly connects buyers and sellers, offering a hassle-free experience with a wide range of properties to choose from. Whether you're looking for a residential or commercial plot, the search process is smooth, efficient, and trustworthy. Highly recommended for anyone looking to invest in land!",
    image: "https://files.plotts.in/images/test4.PNG",
    rating: 5,
  },
];

function Slider() {
  return (
    <>
      <div className="testimonial-slider">
        <Swiper
          modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
          spaceBetween={30}
          slidesPerView={1}
          navigation
          pagination={{ clickable: true }}
          scrollbar={{ draggable: true }}
          loop={true}
          autoplay={{ delay: 7000, disableOnInteraction: false }} // Autoplay settings
        >
          {testimonials.map((testimonial) => (
            <SwiperSlide key={testimonial.id}>
              <div className="testimonial-card">
                <div className="testimonial-content">
                  <p className="testimonial-quote">{testimonial.quote}</p>
                </div>
                <div className="testimonial-rating">
                  {Array.from({ length: testimonial.rating }, (_, index) => (
                    <span key={index} className="star">
                      ★
                    </span>
                  ))}
                </div>
                <div className="testimonial-user">
                  <img
                    src={testimonial.image}
                    alt={testimonial.name}
                    className="user-photo"
                  />
                  <div className="user-info">
                    <h3 className="user-name">{testimonial.name}</h3>
                    <h4 className="user-title">{testimonial.title}</h4>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  );
}

export default Slider;
