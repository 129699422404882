import React from "react";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import { useLoaderContext } from "../Context/LoaderContext";
import LoaderModal from "../Model/LoaderModal";
function MyProperties() {
  const { loader, setLoader } = useLoaderContext();
  const [properties, setProperties] = useState([]);
  const token = localStorage.getItem("token");
  const callMyProperties = async () => {
    setLoader(true);
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/property/display-my-properties`,
        {
          headers: {
            "x-token": token, // Send token in custom header
          },
          withCredentials: true, // Include credentials if needed
        }
      )
      .then((response) => {
        if (response.data.statusCode === 200) {
          setProperties(response.data.data.propertyDetails);
          setLoader(false);
        }
        console.log(response);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    callMyProperties();
  }, []);

  const handelDelete = async (id) => {
    await axios
      .delete(
        `${process.env.REACT_APP_BACKEND_URL}/property/delete-property/${id}`,
        {
          headers: {
            "x-token": token, // Send token in custom header
          },
          withCredentials: true, // Include credentials if needed
        }
      )
      .then((response) => {
        if (response.data.statusCode === 200) {
          callMyProperties();
        }
        console.log(response);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  if (loader) {
    return (
      <div className="col-span-full mx-auto">
        <LoaderModal />
      </div>
    );
  }
  return (
    <div className="shadow-lg flex flex-col gap-6 mt-8 w-full">
      <div className="flex justify-end mr-10">
        <Link to="/addProperty">
          <button className="bg-red-500 py-2 px-4 hover:bg-red-700 rounded-lg text-white font-bold">
            Add Property
          </button>
        </Link>
      </div>
      <div className="overflow-auto md:overflow-none ms-10 me-10 rounded-lg mb-10 shadow-xl">
        <table className="table-auto min-w-max w-full mt-4 border">
          <thead className="bg-green-950 text-white border-slate-300 rounded-lg">
            <tr className="rounded-lg">
              <th className="p-3 text-center">Property</th>
              <th className="p-3 text-center">Title</th>
              <th className="p-3 text-center">State</th>
              <th className="p-3 text-center">Size</th>
              <th className="p-3 text-center">Price</th>
              <th className="p-3 text-center">Views</th>
              <th className="p-3 text-center">Action</th>
            </tr>
          </thead>
          <tbody>
            {properties.length === 0 && !loader ? (
              <tr>
                <td colSpan="6" className="p-3 text-center">
                  No properties found
                </td>
              </tr>
            ) : (
              properties.map((property, index) => (
                <tr
                  key={index}
                  className={`${
                    index % 2 === 0 ? "bg-gray-200" : "bg-gray-300"
                  }`}
                >
                  <td className="p-3 text-center">
                    <div className="flex justify-center">
                      <img
                        src={`${property.property_images[0].file_path}`}
                        alt={property.property_images[0].file_name}
                        className="w-16 h-16 object-cover rounded-lg"
                      />
                    </div>
                  </td>
                  <td className="p-3 text-center  overflow-hidden whitespace-nowrap text-ellipsis max-w-[120px]">
                    {property.title}
                  </td>
                  <td className="p-3 text-center capitalize">
                    {property.state}
                  </td>
                  <td className="p-3 text-center">
                    {property.size} {property.units}
                  </td>
                  <td className="p-3 text-center">{property.price}</td>
                  <td className="p-3 text-center">{property.view_count}</td>
                  <td className="p-3 text-center">
                    <div className="flex justify-center items-center">
                      <Link to={`/property/${property._id}`}>
                        <i className="fa-solid fa-eye fa-lg "></i>
                      </Link>
                      <Link
                        className="text-blue-500 hover:underline"
                        to={`/editProperty/${property._id}`}
                      >
                        <i className="fa-solid fa-pen-to-square fa-lg ps-6"></i>
                      </Link>
                      <Link
                        className="text-blue-500 hover:underline"
                        onClick={() => handelDelete(property._id)}
                      >
                        <i className="fa-solid fa-trash fa-lg text-red-600 ps-6"></i>
                      </Link>
                    </div>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default MyProperties;
