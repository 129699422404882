const PlanTable = ({ data, deletePlan, setPlanModalData }) => {
  return (
    <div className="overflow-x-auto w-full mt-4">
      <table className="table-auto min-w-full border shadow-sm border-slate-300">
        <thead className="bg-[#F9FAFC] text-[#5a5a5b] border-b border-slate-300 text-left">
          <tr>
            <th className="p-3 font-medium text-sm">S.No</th>
            <th className="p-3 font-medium text-sm">Plan Name</th>
            <th className="p-3 font-medium text-sm">Plan Status</th>
            <th className="p-3 font-medium text-sm">Plan Amount</th>
            <th className="p-3 font-medium text-sm">Properties Accessible</th>
            <th className="p-3 font-medium text-sm">Discount</th>
            <th className="p-3 font-medium text-sm">Discount Type</th>
            <th className="p-3 font-medium text-sm">Discount Amount</th>
            <th className="p-3 font-medium text-sm">Final Amount</th>
            <th className="p-3 font-medium text-sm">Action</th>
          </tr>
        </thead>
        <tbody>
          {data.length > 0 ? (
            data.map((plan, index) => (
              <tr
                key={plan._id}
                className="bg-white border-b border-slate-300 align-middle h-auto"
              >
                <td className="p-3 text-xs md:text-sm font-medium">
                  {index + 1}
                </td>
                <td className="p-3 text-xs md:text-sm capitalize">
                  {plan.plan_type}
                </td>
                <td
                  className={`p-3 text-xs md:text-sm capitalize font-medium ${
                    plan?.plan_status === 0 ? "text-red-500" : "text-green-500"
                  }`}
                >
                  {plan.plan_status === 1 ? "Active" : "Inactive"}
                </td>
                <td className="p-3 text-xs md:text-sm capitalize">
                  &#8377; {plan.amount}
                </td>
                <td className="p-3 text-xs md:text-sm">{plan.view_count}</td>
                <td
                  className={`p-3 text-xs md:text-sm font-medium ${
                    plan.discount_status === 1
                      ? "text-green-500"
                      : "text-red-500"
                  }`}
                >
                  {plan.discount_status === 1 ? "Yes" : "No"}
                </td>
                <td className="p-3 text-xs md:text-sm capitalize">
                  {plan.discount_type}
                </td>
                <td className="p-3 text-xs md:text-sm">{plan.discount}</td>
                <td className="p-3 text-xs md:text-sm">
                  &#8377; {plan.final_amount}
                </td>
                <td className="p-3 text-xs md:text-sm space-x-3">
                  <i
                    className="fa-solid fa-pen text-blue-500 cursor-pointer"
                    title="Edit Plan"
                    onClick={() =>
                      setPlanModalData({ open: true, prefilled: plan })
                    }
                  ></i>
                  <i
                    className="fa-solid fa-trash text-red-500 cursor-pointer"
                    title="Delete Plan"
                    onClick={() => deletePlan(plan._id)}
                  ></i>
                </td>
              </tr>
            ))
          ) : (
            <tr className="bg-white text-center">
              <td colSpan={10} className="p-3 text-xs md:text-sm">
                No Plans Found
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default PlanTable;
