import { useForm } from "react-hook-form";
import axios from "axios";
import { toast } from "react-toastify";

const ContactForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    const { name, phone, message } = data;
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/user/send-query`,
        {
          name,
          phone,
          message,
        },
        { withCredentials: true }
      )
      .then((response) => {
        if (response.data.statusCode === 200) {
          toast.success("Contact Details Send");
        } else {
        }
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          toast.error("Server Error !!");
        } else {
          toast.error("Server Error !!");
        }
      });
  };
  return (
    <div className="my-10 lg:w-[70%] mx-auto">
      <div className="">
        <div className="">
          <h1 className="text-xl lg:text-4xl text-green-700 text-center font-bold">
            Looking to buy a property?
          </h1>
        </div>
        <div className="mt-6">
          <div className="bg-slate-200 rounded-lg py-5">
            <div className="px-10">
              <form className="mt-6" onSubmit={handleSubmit(onSubmit)}>
                <div className="grid lg:grid-cols-2 gap-2 lg:gap-4">
                  <div className="">
                    <p className="text-lg mb-1">Name</p>
                    <input
                      className="rounded-lg focus:outline-none w-[100%] py-1 ps-2"
                      type="text"
                      placeholder="Enter your name"
                      name="name"
                      {...register("name", { required: "Name is required" })}
                      required
                    />
                    {errors.name && (
                      <p className="text-red-500 text-sm">
                        {errors.name.message}
                      </p>
                    )}
                  </div>
                  <div className="">
                    <p className="text-lg mb-1">Phone</p>
                    <input
                      className=" rounded-lg focus:outline-none w-[100%] py-1 ps-2"
                      type="number"
                      placeholder="Enter phone"
                      name="phone"
                      {...register("phone", { required: "Phone is required" })}
                      required
                    />
                    {errors.phone && (
                      <p className="text-red-500 text-sm">
                        {errors.phone.message}
                      </p>
                    )}
                  </div>
                </div>
                <div className="grid lg:grid-cols-1">
                  <div className="mt-2">
                    <p className="text-lg mb-1">Message</p>
                    <textarea
                      className="rounded-lg focus:outline-none h-[120px] w-full mt-2 ps-2"
                      placeholder="Enter your message"
                      name="message"
                      {...register("message", {
                        required: "Message is required",
                      })}
                      required
                    />
                    {errors.message && (
                      <p className="text-red-500 text-sm">
                        {errors.message.message}
                      </p>
                    )}
                  </div>
                  <div className="mt-5">
                    <button
                      type="submit"
                      className="rounded-2xl py-3 px-8 text-white font-bold bg-green-950"
                    >
                      Send Message
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
