import React, { createContext, useState } from "react";

export const loginContext = createContext();

export const LoginProvider = ({ children }) => {
  const [isLogin, setIsLogin] = useState("Login");
  const handleLoginState = (value) => {
    setIsLogin(value);
  };
  return (
    <loginContext.Provider value={{ isLogin, setIsLogin, handleLoginState }}>
      {children}
    </loginContext.Provider>
  );
};
