const DistrictTable = ({
  data,
  page,
  setPage,
  handleEditUnitModal,
  handleDeleteModal,
}) => {
  // Calculate the starting and ending indices
  const startIndex = (page - 1) * 20;
  const endIndex = page * 20;
  const currentPageData = data.slice(startIndex, endIndex); // Get exactly 20 items

  return (
    <>
      <div className="overflow-auto md:overflow-none w-5/12">
        <table className="table-auto min-w-max w-full mt-4 border shadow-sm border-slate-300">
          <thead className="bg-[#F9FAFC] text-[#5a5a5b] border-b border-slate-300 text-left">
            <tr>
              <th className="p-3 font-medium text-sm">S.No</th>
              <th className="p-3 font-medium text-sm">State</th>
              <th className="p-3 font-medium text-sm">District</th>
              <th className="p-3 font-medium text-sm">Action</th>
            </tr>
          </thead>
          <tbody>
            {currentPageData.length > 0 ? (
              currentPageData.map((data, index) => (
                <tr
                  key={`${data.state}-${index}`}
                  className="bg-white border-b border-slate-300 align-middle h-auto"
                >
                  <td className="p-3 text-xs md:text-sm font-medium">
                    {startIndex + index + 1}
                  </td>
                  <td className="p-3 text-xs md:text-sm capitalize">
                    {data.state}
                  </td>
                  <td className="p-3 text-xs md:text-sm capitalize">
                    {data.district}
                  </td>
                  <td>
                    <i
                      className="fa-solid fa-pen text-green-400 mx-2 cursor-pointer"
                      onClick={() => handleEditUnitModal(data)}
                    ></i>
                    <i
                      className="fa-solid fa-trash text-red-500 mx-2 cursor-pointer"
                      onClick={() => handleDeleteModal(data, "District")}
                    ></i>
                  </td>
                </tr>
              ))
            ) : (
              <tr className="bg-white text-center">
                <td colSpan={4} className="p-3 text-xs md:text-sm">
                  No States Found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {data.length > 0 && (
        <div className="flex items-center justify-center mt-7 w-5/12">
          <button
            className="px-2 py-1 mx-1 text-sm bg-black text-white rounded-md"
            disabled={page < 2}
            onClick={() => setPage(page - 1)}
          >
            Prev
          </button>
          <span>
            {page}/{Math.ceil(data.length / 20)}
          </span>
          <button
            className="px-2 py-1 mx-1 text-sm bg-black text-white rounded-md"
            disabled={page >= Math.ceil(data.length / 20)}
            onClick={() => setPage(page + 1)}
          >
            Next
          </button>
        </div>
      )}
    </>
  );
};

export default DistrictTable;
