import React from "react";
import land3 from "../Components/assets/9.png";
function Buyer() {
  return (
    <div>
      <div className="flex justify-center flex-col items-center mx-24 mb-24">
        <div>
          <div className="text-center">
            <h1 className="md:text-3xl font-medium">
              How Plotts Works for Buyers
            </h1>
          </div>
          <div className="grid grid-cols-2 items-center mt-8 gap-12">
            {/* image */}
            <div className="">
              <img src={land3} className="rounded-lg" />
            </div>
            {/* content */}
            <div className="flex flex-col gap-3">
              <div className="">
                <div className="flex items-center gap-3">
                  <i className="fa-solid fa-arrow-right fa-xl  text-blue-500"></i>
                  <h1 className="text-lg font-bold">
                    Browse Verified Listings
                  </h1>
                </div>
                <p className="text-slate-700 ps-6">
                  Search through our diverse selection of plots for sale based
                  on location, price, and plot size.
                </p>
              </div>
              <div className="">
                <div className="flex items-center gap-3">
                  <i className="fa-solid fa-arrow-right fa-xl  text-blue-500"></i>
                  <h1 className="text-lg font-bold">
                    View Plot Details and Documents
                  </h1>
                </div>
                <p className="text-slate-700 ps-6">
                  For a small fee, unlock essential plot documents like legal
                  title papers and land approvals.
                </p>
              </div>
              <div className="">
                <div className="flex items-center gap-3">
                  <i className="fa-solid fa-arrow-right fa-xl  text-blue-500"></i>
                  <h1 className="text-lg font-bold">Connect with the Seller</h1>
                </div>
                <p className="text-slate-700 ps-6">
                  Once you're ready, pay a nominal fee to access the seller’s
                  contact details and discuss the plot directly.
                </p>
              </div>
              <div className="">
                <div className="flex items-center gap-3">
                  <i className="fa-solid fa-arrow-right fa-xl  text-blue-500"></i>
                  <h1 className="text-lg font-bold">Finalize the Deal</h1>
                </div>
                <p className="text-slate-700 ps-6">
                  Once satisfied, proceed to make the purchase with complete
                  confidence, knowing all details are verified.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Buyer;
