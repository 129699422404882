import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useLoaderContext } from "../Context/LoaderContext";
import LoaderModal from "../Model/LoaderModal";

function AllProperties() {
  const { loader, setLoader } = useLoaderContext();
  const navigate = useNavigate();
  const [properties, setProperties] = useState([]);
  const [states, setStates] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [mandals, setMandals] = useState([]);
  const [selectedState, setSelectState] = useState();
  const [selectedDistrict, setSelectDistrict] = useState();
  const [searchData, setSearchData] = useState([]);
  const [page, setPage] = useState(1);
  const token = localStorage.getItem("token");
  useEffect(() => {
    setLoader(true);
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/property/display-all-properties`,
        {
          headers: {
            "x-token": token,
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        if (response.data.statusCode === 200) {
          setProperties(response.data.data);
          setLoader(false);
        }
        console.log(response);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/state/display-states`, {
        withCredentials: true,
      })
      .then((response) => {
        if (response.data.statusCode === 200) {
          setStates(response.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (selectedState) {
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/state/display-district`,
          {
            state: selectedState,
          },
          {
            withCredentials: true,
          }
        )
        .then((response) => {
          if (response.data.statusCode === 200) {
            setDistricts(response.data.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [selectedState]);

  useEffect(() => {
    if (selectedDistrict) {
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/state/display-mandals`,
          {
            district: selectedDistrict,
          },
          {
            withCredentials: true,
          }
        )
        .then((response) => {
          if (response.data.statusCode === 200) {
            setMandals(response.data.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [selectedDistrict]);

  const handleView = (id) => {
    navigate(`/property/${id}`);
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const handleClear = () => {
    reset({
      state: "",
      district: "",
      mandal: "",
    });
  };

  const submit = (details) => {
    console.log(details)
    console.log('=======')
    const { state, district, mandal } = details;
    console.log(details)
    if (state) {
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/property/search-properties`,
          {
            state,
            district,
            mandal,
          },
          {
            withCredentials: true, // Include credentials if needed
          }
        )
        .then((response) => {
          if (response.data.statusCode === 200) {
            setSearchData(response.data.data);
            handleClear();
            navigate("/search", { state: { searchData: response.data.data } });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  if (loader) {
    return (
      <div className="col-span-full mx-auto">
        <LoaderModal />
      </div>
    );
  }

  return (
    <>
      <div className="bg-slate-50  flex justify-center items-center h-44">
        <div className="text-center">
          <h1 className="text-green-950 text-3xl font-bold">Properties</h1>
          <p className="text-green-950 text-xl mt-3">Home - Properties</p>
        </div>
      </div>
      <div className="hero-search p-4 bg-green-900 mt-10 rounded-lg w-11/12 sm:w-10/12 md:w-fit mx-auto">
        <form
          className="lg:flex gap-2 lg:gap-5 justify-center space-y-2 lg:space-y-0"
          onSubmit={handleSubmit(submit)}
        >
          {/* State */}
          <div>
            <p className="text-white mb-1">State</p>
            <select
              className="bg-slate-50 focus:outline-none rounded-lg p-2 w-[100%] lg:w-auto capitalize"
              {...register("state", {
                required: "This field is required",
              })}
              onChange={(e) => setSelectState(e.target.value)}
            >
              <option value="">Select An Option</option>
              {states.length > 0 &&
                states.map((option) => (
                  <option
                    key={option._id}
                    value={option.state}
                    className="capitalize"
                  >
                    {option.state}
                  </option>
                ))}
            </select>
          </div>
          {/* District */}
          <div>
            <p className="text-white mb-1">District</p>
            <select
              className="bg-slate-50 focus:outline-none rounded-lg p-2 w-[100%] lg:w-auto capitalize"
              
              onChange={(e) => setSelectDistrict(e.target.value)}
            >
              <option value="">Select An Option</option>
              {districts.length > 0 &&
                districts.map((option) => (
                  <option
                    key={option._id}
                    value={option.district}
                    className="capitalize"
                  >
                    {option.district}
                  </option>
                ))}
            </select>
          </div>
          {/* Mandal */}
          <div>
            <p className="text-white mb-1">Tehsil/Block/Mandal</p>
            <select
              className="bg-slate-50 focus:outline-none rounded-lg p-2 w-[100%] lg:w-auto capitalize"
              
            >
              <option value="">Select An Option</option>
              {mandals.length > 0 &&
                mandals.map((option) => (
                  <option
                    key={option._id}
                    value={option.mandal}
                    className="capitalize"
                  >
                    {option.mandal}
                  </option>
                ))}
            </select>
          </div>
          {/* Submit button */}
          <div>
            <p className="opacity-0">Search</p>
            <button
              type="submit"
              className="md:block bg-yellow-400 hover:bg-yellow-600 text-lg hover:text-white text-black font-medium py-1 px-5 rounded-xl"
            >
              Search
            </button>
          </div>

          {/* Clear button */}
          {/* <button
                    type="button"
                    className="md:block bg-red-500 hover:bg-red-700 text-lg text-white font-medium py-1 px-5 rounded-xl my-2"
                    onClick={handleClear}
                  >
                    Clear
                  </button> */}
        </form>
      </div>

      <div className="mt-7 ms-10 me-10">
        <div className="grid grid-cols-1 md:grid-cols-3  md:gap-6 max-sm:gap-2 justify-center items-center md:pe-24 md:ps-24 md:pt-12 md:pb-12 max-sm:mt-12">
          {properties.length === 0 && !loader ? (
            <p className="md:col-span-3 mt-5 text-xl font-medium text-center">
              No Property Found
            </p>
          ) : (
            properties.slice(page * 15 - 15, page * 15).map((item, index) => (
              <div
                key={item._id}
                className="shadow-lg shadow-black-500 rounded-lg mb-6 relative bg-white"
              >
                {/* image */}
                <div className="h-[225px]">
                  <img
                    className="w-full h-full rounded-md"
                    src={`${item.property_images[0].file_path}`}
                    alt={item.property_images[0].file_name}
                  />
                </div>
                <div className="p-4">
                  <p className="font-medium text-xl line-clamp-1">
                    {item.title}
                  </p>
                  {/* Location */}
                  <div className="flex justify-between text-slate-500 text-sm">
                    <h1>
                      <span>
                        <i className="fa-solid fa-location-dot pe-1"></i>
                      </span>
                      <span className="capitalize">
                        {" "}
                        {item.mandal}, {item.district}, {item.state}
                      </span>
                    </h1>
                    <h1 className="font-medium">
                      {item.size} {item.units}
                    </h1>
                  </div>
                  {/* Button */}

                  <div className="flex items-center gap-6 mt-3 pt-3 border-t-2 border-slate-200 w-full">
                    <span className="w-[50%] text-yellow-500 p-2 rounded-lg font-bold">
                      {" "}
                      <i className="fa-solid fa-indian-rupee-sign pe-1"></i>
                      {Math.ceil(item.price / item.size)} / {item.units}
                    </span>
                    <button
                      className="bg-green-950 rounded-lg px-6 py-1 text-white font-bold items-start hover:bg-blue-700 w-[50%] h-fit"
                      onClick={() => {
                        handleView(item._id);
                      }}
                    >
                      View
                    </button>
                  </div>
                  <p className="text-sm text-end">
                    Total Views: {item.view_count}
                  </p>
                </div>
              </div>
            ))
          )}
        </div>
        {properties.length > 0 && (
          <div className="flex items-center justify-center mb-12">
            <button
              className="px-2 py-1 mx-1 text-sm bg-black text-white rounded-md"
              disabled={page < 2}
              onClick={() => setPage(page - 1)}
            >
              Prev
            </button>
            <span>
              {page}/{Math.ceil(properties.length / 15)}
            </span>
            <button
              className="px-2 py-1 mx-1 text-sm bg-black text-white rounded-md"
              disabled={page >= Math.ceil(properties.length / 15)}
              onClick={() => setPage(page + 1)}
            >
              Next
            </button>
          </div>
        )}
      </div>
    </>
  );
}

export default AllProperties;
