const UserTable = ({ page, setPage, data, selectedFilter }) => {
  let filterData;

  if (selectedFilter === "All") {
    filterData = data;
  } else if (selectedFilter === "Buyer") {
    filterData = data.filter((data) => data.role === "buyer");
  } else if (selectedFilter === "Seller") {
    filterData = data.filter((data) => data.role === "seller");
  } else {
    filterData = data.filter((data) => data.role === "agent");
  }

  return (
    <>
      <div className="overflow-auto w-full md:overflow-none">
        <table className="table-auto min-w-max w-full mt-4 border shadow-sm border-slate-300">
          <thead className="bg-[#F9FAFC] text-[#5a5a5b] border-b border-slate-300 text-left">
            <tr>
              <th className="p-3 font-medium text-sm">S.No</th>
              <th className="p-3 font-medium text-sm">Name</th>
              <th className="p-3 font-medium text-sm">State</th>
              <th className="p-3 font-medium text-sm">Role</th>
              <th className="p-3 font-medium text-sm">Phone</th>
              <th className="p-3 font-medium text-sm">Email</th>
              <th className="p-3 font-medium text-sm">Properties</th>
            </tr>
          </thead>
          <tbody>
            {filterData.length > 0 ? (
              filterData.slice(page * 15 - 15, page * 15).map((data, index) => (
                <tr
                  key={data._id}
                  className="bg-white border-b border-slate-300 align-middle h-auto"
                >
                  <td className="p-3 text-xs md:text-sm font-medium">
                    {(page - 1) * 15 + index + 1}
                  </td>
                  <td className="p-3 text-xs md:text-sm capitalize">
                    {data.name}
                  </td>
                  <td
                    className={`p-3 text-xs md:text-sm capitalize ${
                      data?.state.length < 1 && "text-red-500"
                    }`}
                  >
                    {data?.state.length > 0 ? data?.state : "NA"}
                  </td>
                  <td
                    className={`p-3 text-xs md:text-sm capitalize ${
                      data.role === "buyer"
                        ? "text-blue-500"
                        : data.role === "seller"
                        ? "text-green-500"
                        : "text-orange-500"
                    }`}
                  >
                    {data.role}
                  </td>
                  <td className="p-3 text-xs md:text-sm">{data.phone}</td>
                  <td className="p-3 text-xs md:text-sm">{data.email}</td>
                  <td className="p-3 text-xs md:text-sm">
                    {data.propertiesCount}
                  </td>
                </tr>
              ))
            ) : (
              <tr className="bg-white text-center">
                <td colSpan={7} className="p-3 text-xs md:text-sm">
                  No User Found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {filterData.length > 0 && (
        <div className="flex items-center justify-center mt-7">
          <button
            className="px-2 py-1 mx-1 text-sm bg-black text-white rounded-md"
            disabled={page < 2}
            onClick={() => setPage(page - 1)}
          >
            Prev
          </button>
          <span>
            {page}/{Math.ceil(filterData.length / 15)}
          </span>
          <button
            className="px-2 py-1 mx-1 text-sm bg-black text-white rounded-md"
            disabled={page >= Math.ceil(filterData.length / 15)}
            onClick={() => setPage(page + 1)}
          >
            Next
          </button>
        </div>
      )}
    </>
  );
};

export default UserTable;
