import React, { useState, useEffect } from "react";
import axios from "axios";
import Users from "./admin/users/Users";
import { useLocation } from "react-router-dom";
const Checkout = () => {
  const location = useLocation();
  const data = location.state;
  const token = localStorage.getItem("token");
  const [user, setUser] = useState();
  const userId = localStorage.getItem("id");
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/user/display-user-details`, {
        withCredentials: true,
        headers: {
          "x-token": token,
        },
      })
      .then((response) => {
        if (response.data.statusCode === 200) {
          setUser(response.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handlePayment = () => {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/payment/checkout`,
        { user_id: userId, plan_id: data.data._id }, // This is the body (replace with actual data if needed)
        {
          headers: { "x-token": token },
          withCredentials: true,
        }
      )
      .then((response) => {
        if (response.data.statusCode == 200) {
          window.location.href = response.data.data;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="p-5 py-10">
      <div className="lg:w-[70%] mx-auto">
        <table className="table-auto w-full border-2">
          <thead className="bg-green-950 text-center text-white">
            <tr>
              <th className="border-2 border-slate-400">Quantity</th>
              <th className="border-2 border-slate-400">Product Name</th>
              <th className="border-2 border-slate-400">Price</th>
            </tr>
          </thead>
          <tbody className="text-center">
            <tr>
              <td className="border-2 border-slate-400">1</td>
              <td className="border-2 border-slate-400">
                {data.data.plan_type}
              </td>
              <td className="border-2 border-slate-400">
                <span>
                  <i class="fa-solid fa-indian-rupee-sign fa-md pe-1"></i>
                </span>
                {data.data.final_amount}{" "}
              </td>
            </tr>
          </tbody>
        </table>
        {/* <div className="text-right">
          <button className="bg-yellow-400 font-bold px-6 text-black py-1 rounded-lg mt-3">
            Pay Now
          </button>
        </div> */}
      </div>
      <div className="grid md:grid-cols-2 md:gap-24  md:mt-12 mt-6 md:mx-40">
        <div className="ps-5">
          {user ? (
            <>
              <div>
                <h1 className="text-2xl font-medium">User Details</h1>

                <p className="text-slate-600 text-lg">
                  <span className="font-medium">Name:</span> {user.name}
                </p>
                <p className="text-slate-600 text-lg">
                  <span className="font-medium">Phone:</span> {user.phone}
                </p>
                <p className="text-slate-600 text-lg">
                  <span className="font-medium">Email:</span> {user.email}
                </p>
              </div>
            </>
          ) : null}
        </div>
        <div className="mt-4 md:mt-0 px-2">
          <p className="text-2xl font-medium ">Totals</p>
          {/* <div className="flex justify-between mt-4 text-lg md:text-xl text-slate-600 font-medium">
            <p>Subtotal</p>
            <p>{plan_price}</p>
          </div> */}
          <div className="bg-slate-200 h-[2px] mt-2"></div>
          <div className="flex justify-between mt-2 text-lg md:text-xl text-slate-600 font-medium">
            <p>Shipping Free</p>
            <p>
              <span>
                <i class="fa-solid fa-indian-rupee-sign fa-md pe-1"></i>
              </span>
              0{" "}
            </p>
          </div>
          <div className="bg-slate-200 h-[2px] w-full mt-2"></div>
          <div className="flex justify-between mt-2 text-lg md:text-xl text-slate-600 font-medium">
            <p className="font-medium">Total</p>
            <p>
              <span>
                <i class="fa-solid fa-indian-rupee-sign fa-md pe-1"></i>
              </span>
              {data.data.final_amount}
            </p>
          </div>
          <div className="bg-slate-200 h-[2px] w-full mt-2"></div>
          <div className="text-center md:text-right mt-3">
            <button
              className="bg-yellow-400 font-bold px-6 text-black py-1 rounded-lg mt-3"
              onClick={handlePayment}
            >
              Pay Now
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Checkout;
