import React from "react";
import data from "../Components/assets/productData";
import { useState } from "react";
import land3 from "../Components/assets/9.png";
import land5 from "../Components/assets/5.png";
import land6 from "../Components/assets/8.png";
import Choose from "../Components/Choose";
function About() {
  const [imageIndex, setImageIndex] = useState(1);

  const handleIndex = (index) => {
    setImageIndex(index);
  };

  return (
    <>
      <div className="bg-slate-50  flex justify-center items-center h-44">
        <div className="text-center">
          <h1 className="text-green-950 text-3xl font-bold">About Us</h1>
          <p className="text-green-950 text-xl mt-3">Home - About Us</p>
        </div>
      </div>

      <div className="py-5 lg:py-24">
        <div className=" items-center p-5 lg:p-0 lg:mx-24">
          <div>
            <div className="grid lg:grid-cols-2 items-center mt-8 gap-12">
              {/* content */}
              <div className="flex flex-col gap-3">
                <h1 className="text-xl md:text-3xl text-green-500 font-medium">
                  Welcome to Plotts – India's Leading Platform for Plot Buyers
                  and Sellers
                </h1>
                <p>
                  Are you looking to buy or sell a plot in India? At Plotts, we
                  specialize in connecting plot buyers and sellers/Agent across
                  the country. Our platform is designed to make your plot search
                  or sale easy, transparent, and efficient. Whether you're
                  seeking residential, commercial, or agricultural plots, we
                  ensure seamless transactions by giving you direct access to
                  verified sellers and essential plot documents for a small fee.
                </p>
              </div>
              <div className="">
                <img src={land3} className="rounded-lg" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Choose />
      <div className="items-center p-5 lg:p-0 lg:mx-24 mb-24">
        <div>
          <div className="text-center">
            <h1 className="text-xl md:text-3xl text-green-500 font-medium">
              How Plotts Works for Buyers
            </h1>
          </div>
          <div className="grid lg:grid-cols-2 items-center mt-8 gap-12">
            {/* image */}
            <img src={land6} alt="land img" className="rounded-lg" />
            {/* content */}
            <div className="flex flex-col gap-3">
              <div className="">
                <div className="flex items-center gap-3">
                  <i className="fa-solid fa-arrow-right fa-xl  text-green-950"></i>
                  <h1 className="text-lg font-bold">
                    Browse Verified Listings
                  </h1>
                </div>
                <p className="text-slate-700 ps-6">
                  Search through our diverse selection of plots for sale based
                  on location, price, and plot size.
                </p>
              </div>
              <div className="">
                <div className="flex items-center gap-3">
                  <i className="fa-solid fa-arrow-right fa-xl  text-green-950"></i>
                  <h1 className="text-lg font-bold">
                    View Plot Details and Documents
                  </h1>
                </div>
                <p className="text-slate-700 ps-6">
                  For a small fee, unlock essential plot documents like legal
                  title papers and land approvals.
                </p>
              </div>
              <div className="">
                <div className="flex items-center gap-3">
                  <i className="fa-solid fa-arrow-right fa-xl  text-green-950"></i>
                  <h1 className="text-lg font-bold">Connect with the Seller</h1>
                </div>
                <p className="text-slate-700 ps-6">
                  Once you're ready, pay a nominal fee to access the seller’s
                  contact details and discuss the plot directly.
                </p>
              </div>
              <div className="">
                <div className="flex items-center gap-3">
                  <i className="fa-solid fa-arrow-right fa-xl  text-green-950"></i>
                  <h1 className="text-lg font-bold">Finalize the Deal</h1>
                </div>
                <p className="text-slate-700 ps-6">
                  Once satisfied, proceed to make the purchase with complete
                  confidence, knowing all details are verified.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-slate-50 py-24">
        <div className="items-center p-5 lg:p-0 lg:mx-24">
          <div>
            <h1 className="text-center text-xl md:text-3xl text-green-500 font-medium">
              How Plotts Works for Sellers
            </h1>
            <div className="grid lg:grid-cols-2 items-center mt-8 gap-12">
              {/* content */}
              <div className="flex flex-col gap-3">
                <div className="">
                  <div className="flex items-center gap-3">
                    <i className="fa-solid fa-arrow-right fa-xl  text-green-950"></i>
                    <h1 className="text-lg font-bold">List Your Plot</h1>
                  </div>
                  <p className="text-slate-700 ps-6">
                    Easily create a listing with all necessary details,
                    including plot size, price, and location.
                  </p>
                </div>
                <div className="">
                  <div className="flex items-center gap-3">
                    <i className="fa-solid fa-arrow-right fa-xl  text-green-950"></i>
                    <h1 className="text-lg font-bold">
                      Get Direct Inquiries from Buyers
                    </h1>
                  </div>
                  <p className="text-slate-700 ps-6">
                    Interested buyers can access your contact details for a
                    small fee, bringing you serious inquiries only.
                  </p>
                </div>
                <div className="">
                  <div className="flex items-center gap-3">
                    <i className="fa-solid fa-arrow-right fa-xl  text-green-950"></i>
                    <h1 className="text-lg font-bold">
                      Share Verified Documents
                    </h1>
                  </div>
                  <p className="text-slate-700 ps-6">
                    Upload and share plot documents securely to increase buyer
                    trust and accelerate the sale process.
                  </p>
                </div>
                <div className="">
                  <div className="flex items-center gap-3">
                    <i className="fa-solid fa-arrow-right fa-xl  text-green-950"></i>
                    <h1 className="text-lg font-bold">Close the Sale</h1>
                  </div>
                  <p className="text-slate-700 ps-6">
                    Communicate directly with potential buyers to negotiate and
                    finalize the deal.
                  </p>
                </div>
              </div>
              <div className="">
                <img src={land5} className="rounded-lg" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default About;
