import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import { useLoaderContext } from "../Context/LoaderContext";
import LoaderModal from "../Model/LoaderModal"; // Assuming you have this loader component

function Properties() {
  const { loader, setLoader } = useLoaderContext();
  const navigate = useNavigate();
  const [topProperties, setTopProperties] = useState([]);
  useEffect(() => {
    setLoader(true);
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/property/display-top-properties`,
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        if (response.data.statusCode === 200) {
          setTopProperties(response.data.data);
          setLoader(false); // Stop loader after data is fetched
        } else {
          setLoader(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoader(false); // Stop loader in case of an error
      });
  }, [setLoader]);

  const handleView = (id) => {
    navigate(`/property/${id}`);
  };

  // Show loader while data is being fetched
  if (loader) {
    return (
      <div className="col-span-full mx-auto">
        <LoaderModal />
      </div>
    );
  }

  return (
    <>
      <div className="w-full md:mt-24 max-sm:mt-12">
        {/* Heading Section */}
        <div className="flex justify-center items-center flex-col md:pt-12 max-sm:pt-6 text-center">
          <h1 className="text-2xl lg:text-4xl text-green-800 font-bold">
            Properties for Sale
          </h1>
          <p className="text-slate-500 mt-2 lg:w-[50%]">
            Browse a wide range of plots available for sale, ideal for
            residential, agricultural, and commercial projects. Secure your
            dream location with ease through our trusted platform.
          </p>
        </div>

        {/* Property Grid */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-8 p-6">
          {/* If no properties are found and loader is not active */}
          {topProperties.length === 0 && !loader ? (
            <div className="text-center col-span-3">
              <h1 className="text-2xl font-bold text-gray-700">
                Property not found
              </h1>
            </div>
          ) : (
            // Map over the properties if available
            topProperties.map((item) => (
              <div
                key={item._id}
                className="shadow-md rounded-lg mb-6 relative bg-white hover:scale-105 transition-transform duration-200"
              >
                {/* Image Section */}
                <div className="h-[225px] overflow-hidden rounded-t-lg">
                  <img
                    className="w-full h-full object-cover rounded-t-lg"
                    src={`${item.property_images[0].file_path}`}
                    alt={item.property_images[0].file_name}
                  />
                </div>

                {/* Property Details */}
                <div className="p-4 space-y-2">
                  <p className="font-semibold text-lg text-gray-900 truncate">
                    {item.title}
                  </p>

                  {/* Location */}
                  <div className="flex justify-between text-gray-500 text-sm">
                    <p>
                      <i className="fa-solid fa-location-dot pe-1"></i>
                      <span className="capitalize">
                        {" "}
                        {item.mandal}, {item.district}, {item.state}
                      </span>
                    </p>
                    <p className="font-medium">
                      {item.size} {item.units}
                    </p>
                  </div>

                  {/* Price and View Button */}
                  <div className="flex justify-between items-center mt-3 pt-3 border-t border-gray-200">
                    <span className="text-yellow-500 font-bold">
                      <i className="fa-solid fa-indian-rupee-sign pe-1"></i>
                      {Math.ceil(item.price / item.size)} / {item.units}
                    </span>
                    <button
                      className="bg-green-700 hover:bg-green-600 text-white font-bold py-2 px-4 rounded-lg transition-colors"
                      onClick={() => handleView(item._id)}
                    >
                      View
                    </button>
                  </div>

                  {/* View Count */}
                  <p className="text-sm text-right text-gray-500">
                    Total Views: {item.view_count}
                  </p>
                </div>
              </div>
            ))
          )}

          {/* View All Button */}
          <Link
            to="/properties"
            className="col-span-full mx-auto px-6 py-2 bg-yellow-500 text-black rounded-md font-medium hover:bg-yellow-400 transition-colors"
          >
            View All Plots
          </Link>
        </div>
      </div>
    </>
  );
}

export default Properties;
