import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import PropertyTable from "./PropertyTable";
import AdminSidebar from "../../../Components/AdminSidebar";

const AllUsersProperties = () => {
  const [properties, setProperties] = useState([]);
  const [page, setPage] = useState(1);
  const token = localStorage.getItem("token");
  useEffect(() => {
    const fetchProperties = setTimeout(() => {
      axios
        .get(`${process.env.REACT_APP_BACKEND_URL}/admin/all-properties`, {
          headers: {
            "x-token": token, // Send the token directly in a custom header (e.g., 'x-auth-token')
          },
          withCredentials: true,
        })
        .then((response) => {
          if (response.data.statusCode === 200) {
            setProperties(response.data.data);
          }
        })
        .catch(() => {
          toast.error("Failed to fetch users");
        });
    });
    return () => {
      clearTimeout(fetchProperties);
    };
  }, []);

  return (
    <div className="p-7 flex flex-col lg:flex-row gap-x-7 gap-y-5 lg:gap-y-0">
      <AdminSidebar />
      <div className="w-full">
        <h3 className="text-2xl font-medium mb-3">Properties</h3>
        <PropertyTable page={page} setPage={setPage} data={properties} />
      </div>
    </div>
  );
};

export default AllUsersProperties;
