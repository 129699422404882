// LoaderContext.js
import { createContext, useContext, useState } from "react";

export const LoaderContext = createContext();

export const LoaderProvider = ({ children }) => {
  const [loader, setLoader] = useState(false);
  return (
    <LoaderContext.Provider value={{ loader, setLoader }}>
      {children}
    </LoaderContext.Provider>
  );
};

// Custom hook to use the loader context
export const useLoaderContext = () => {
  return useContext(LoaderContext);
};
