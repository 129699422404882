import React from "react";

function Choose() {
  return (
    <div className="my-24 mt-0 bg-green-950 p-5 lg:p-24 ">
      <h1 className="text-xl lg:text-4xl text-white font-bold">
        — Why Choose Us?
      </h1>
      <div className="mt-16 ">
        <div className="grid lg:grid-cols-4 gap-4">
          {/* col 1 */}
          <div className="">
            <i className="fa-solid fa-magnifying-glass fa-2xl text-green-950 bg-yellow-400 p-6 rounded-tl-[30px] -mb-16 ms-0"></i>
            <div className="shadow-lg p-8 bg-white rounded-bl-[40px]">
              <h1 className="text-xl font-bold">Exclusive Plot Listings</h1>
              <p className="text-slate-700 mt-4">
                Browse a diverse selection of plots for sale, from prime city
                spots to serene countryside locations.
              </p>
            </div>
          </div>
          {/* col 2 */}
          <div className="">
            <i className="fa-solid fa-lock fa-2xl text-green-950 bg-yellow-400 p-6 rounded-tl-[30px] -mb-16 ms-0"></i>
            <div className="shadow-lg p-8 bg-white rounded-bl-[40px]">
              <h1 className="text-xl font-bold">
                Direct Buyer-Seller Connection
              </h1>
              <p className="text-slate-700 mt-4">
                Connect with sellers directly through our platform, with access
                to contact details and key documents for a small fee.
              </p>
            </div>
          </div>
          {/* col 1 */}
          <div className="">
            <i className="fa-solid fa-signal fa-2xl text-green-950 bg-yellow-400 p-6 rounded-tl-[30px] -mb-16 ms-0 "></i>
            <div className="shadow-lg p-8 bg-white rounded-bl-[40px]">
              <h1 className="text-xl font-bold">
                Simple and Transparent Process
              </h1>
              <p className="text-slate-700 mt-4">
                Enjoy a hassle-free experience with clear steps for finding,
                verifying, and securing plots.
              </p>
            </div>
          </div>
          {/* col 2 */}
          <div className="">
            <i className="fa-solid fa-sack-dollar fa-2xl text-green-950 bg-yellow-400 p-6 rounded-tl-[30px] -mb-16 ms-0"></i>
            <div className="shadow-lg p-8 bg-white rounded-bl-[40px]">
              <h1 className="text-xl font-bold">Affordable Service Fees</h1>
              <p className="text-slate-700 mt-4">
                Pay only a small fee when requesting contact details and plot
                documents, keeping the process budget-friendly.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Choose;
