import React, { useState, useEffect, useRef, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import axios from "axios";
import { useLoaderContext } from "../Context/LoaderContext";

function EditProperty() {
  const { loader, setLoader } = useLoaderContext();
  const selectRef = useRef(null);
  const [isDecTypeSelect, setIsDocTypeSelect] = useState("");
  const [propertyDetails, setPropertyDetails] = useState({});
  const [states, setStates] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [mandals, setMandals] = useState([]);
  const [selectedState, setSelectState] = useState();
  const [selectedDistrict, setSelectDistrict] = useState();
  // const [selectedMandal, setSelectMandal] = useState(property?.mandal);
  const [sizeUnits, setSizeUnits] = useState([]);
  const [existingImages, setExistingImages] = useState([]);
  const [fileUpload, setFilUpload] = useState([]);

  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const { id } = useParams();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      units: propertyDetails?.units,
      price: propertyDetails?.price,
      state: propertyDetails?.state,
      district: propertyDetails?.district,
      mandal: propertyDetails?.mandal,
      size: propertyDetails?.size,
      description: propertyDetails?.description,
    },
  });

  // get property details

  const fetchDetails = useCallback(async () => {
    await axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/property/display-one-property/${id}`,
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        if (response.data.statusCode === 200) {
          const propertyData = response.data.data;
          setPropertyDetails(propertyData[0]);

          reset({
            units: propertyData[0]?.units,
            price: propertyData[0]?.price,
            state: propertyData[0]?.state,
            district: propertyData[0]?.district,
            mandal: propertyData[0]?.mandal,
            size: propertyData[0]?.size,
            description: propertyData[0]?.description,
          });
          setSelectState(propertyData[0]?.state);
          setSelectDistrict(propertyData[0]?.district);
          setExistingImages(
            propertyData[0]?.property_images?.map((image) => ({
              filePath: image.file_path,
              fileName: image.file_name,
            })) || []
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [reset, id]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/admin/display-units`, {
        withCredentials: true,
        headers: {
          "x-token": token,
        },
      })
      .then((response) => {
        if (response.data.statusCode === 200) {
          setSizeUnits(response.data.data);
          console.log(response.data.data);
          fetchDetails();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id, token, reset, fetchDetails]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/state/display-states`, {
        withCredentials: true, // Include credentials if needed
      })
      .then((response) => {
        if (response.data.statusCode === 200) {
          setStates(response.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (selectedState) {
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/state/display-district`,
          {
            state: selectedState,
          },
          {
            withCredentials: true, // Include credentials if needed
          }
        )
        .then((response) => {
          if (response.data.statusCode === 200) {
            setDistricts(response.data.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [selectedState]);

  useEffect(() => {
    if (selectedDistrict) {
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/state/display-mandals`,
          {
            district: selectedDistrict,
          },
          {
            withCredentials: true, // Include credentials if needed
          }
        )
        .then((response) => {
          if (response.data.statusCode === 200) {
            setMandals(response.data.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [selectedDistrict]);

  const removeFileDate = (index) => {
    setFilUpload((prev) => prev.filter((_, id) => id !== index));
  };
  const handleFileUpload = (file) => {
    setIsDocTypeSelect("");
    let currValue = selectRef.current.value;
    setFilUpload((prev) => [
      ...prev,
      {
        file,
        file_type: currValue,
      },
    ]);
    selectRef.current.value = "";
  };

  const removeExistingImage = (index) => {
    setExistingImages((prev) => prev.filter((_, id) => id !== index));
  };

  const removeExistingDoc = (file_type) => {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/property/delete-property-doc`,
        {
          file_type,
          id: id,
        },
        // JSON payload
        {
          headers: {
            "Content-Type": "application/json",
            "x-token": token, // JSON content type
          },
          withCredentials: true, // Send cookies with the request
        }
      )
      .then((response) => {
        if (response.data.statusCode === 200) {
          setPropertyDetails((prevProperty) => {
            const updatedProperty = { ...prevProperty };
            delete updatedProperty[file_type];
            return updatedProperty;
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSelectDocType = () => {
    setIsDocTypeSelect(selectRef.current.value);
  };

  const submit = (details) => {
    setLoader(true);
    const formData = new FormData();
    Object.keys(details).forEach((key) => {
      if (key !== "propertyImage" || key !== "documents") {
        formData.append(key, details[key]);
      }
    });
    if (details.property_images && details.property_images.length > 0) {
      for (let i = 0; i < details.property_images.length; i++) {
        formData.append("propertyImage", details.property_images[i]);
      }
    }
    // Append documents
    if (fileUpload && fileUpload.length > 0) {
      for (let i = 0; i < fileUpload.length; i++) {
        formData.append(fileUpload[i].file_type, fileUpload[i].file);
      }
    }
    formData.append("existing_images", JSON.stringify(existingImages));
    const response = axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/property/edit-property/${id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            "x-token": token,
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        if (response.data.statusCode == 200) {
          setLoader(false);
          toast.success("Edit Successfully");
          navigate("/myproperties");
        }
      })
      .catch((err) => {
        toast.error("Server Error");
        console.log(err);
      });
  };

  return (
    <div className="w-full mt-10">
      <form onSubmit={handleSubmit(submit)}>
        <div className="flex justify-center">
          <div className="bg-white ps-4 pe-4 gap-2 w-full lg:w-5/6 2xl:w-4/6">
            <div className="flex justify-center">
              <h1 className="mt-4 text-green-950 font-bold text-3xl">
                Edit Land Details
              </h1>
            </div>
            <div className="grid grid-cols-2 md:grid-cols-3 mt-8 gap-5">
              {/*  State , District ,City */}
              <div className="flex flex-col">
                <p className="pb-1 font-medium text-lg">State*</p>
                <div className="flex gap-5 items-center bg-slate-300 p-2 rounded-md">
                  <select
                    name="state"
                    className="bg-slate-300 focus:outline-none w-full capitalize"
                    {...register("state", {
                      required: "This field is required",
                    })}
                    onChange={(e) => setSelectState(e.target.value)}
                  >
                    <option value="">Select an option</option>
                    {states.length > 0 &&
                      states.map((option) => (
                        <option
                          key={option._id}
                          value={option.state}
                          className="capitalize"
                        >
                          {option.state}
                        </option>
                      ))}
                  </select>
                </div>
                <p className="text-red-500">{errors.state?.message}</p>
              </div>
              <div className="flex flex-col">
                <p className="pb-1 font-medium text-lg">District*</p>
                <div className="flex gap-5 items-center bg-slate-300 p-2 rounded-md">
                  <select
                    name="district"
                    className="bg-slate-300 focus:outline-none w-full capitalize"
                    {...register("district", {
                      required: "This field is required",
                    })}
                    onChange={(e) => setSelectDistrict(e.target.value)}
                  >
                    <option value="">Select an option</option>
                    {districts.length > 0 &&
                      districts.map((option) => (
                        <option
                          key={option._id}
                          value={option.district}
                          selected={
                            option.district == propertyDetails.district
                              ? true
                              : false
                          }
                          className="capitalize"
                        >
                          {option.district}
                        </option>
                      ))}
                  </select>
                </div>
                <p className="text-red-500">{errors.country?.message}</p>
              </div>
              <div className="flex flex-col">
                <p className="pb-1 font-medium text-lg">Tehsil/Block/Mandal*</p>
                <div className="flex gap-5 items-center bg-slate-300 p-2 rounded-md">
                  <select
                    name="mandal"
                    className="bg-slate-300 focus:outline-none w-full capitalize"
                    {...register("mandal", {
                      required: "This field is required",
                    })}
                  >
                    <option value="">Select an option</option>
                    {mandals.length > 0 &&
                      mandals.map((option) => (
                        <option
                          key={option._id}
                          value={option.mandal}
                          selected={
                            option.mandal == propertyDetails.mandal
                              ? true
                              : false
                          }
                          className="capitalize"
                        >
                          {option.mandal}
                        </option>
                      ))}
                  </select>
                </div>
                <p className="text-red-500">{errors.mandal?.message}</p>
              </div>
              {/* Price */}
              <div className="flex flex-col">
                <p className="pb-1 font-medium text-lg">Select Units*</p>
                <div className="flex gap-5 items-center bg-slate-300 p-2 rounded-md">
                  <select
                    name="units"
                    id="units"
                    {...register("units", { required: "Select a unit" })}
                    className="bg-transparent focus-visible:outline-none w-full"
                    required
                  >
                    <option value="">Select Units</option>
                    {sizeUnits.length > 0 &&
                      sizeUnits.map((unit) => (
                        <option
                          value={unit.unit}
                          key={unit._id}
                          selected={
                            unit.unit == propertyDetails.units ? true : false
                          }
                          className="capitalize"
                        >
                          {unit.unit}
                        </option>
                      ))}
                  </select>
                </div>
                <p className="text-red-500">{errors.size?.message}</p>
              </div>
              <div className="flex flex-col">
                <p className="pb-1 font-medium text-lg">Total Plot Size*</p>
                <div className="flex gap-5 items-center bg-slate-300 p-2 rounded-md">
                  <input
                    required
                    placeholder="Size"
                    type="number"
                    className="bg-slate-300 focus:outline-none w-full"
                    {...register("size", {
                      required: "Size is required",
                    })}
                  />
                </div>
                <p className="text-red-500">{errors.size?.message}</p>
              </div>
              <div className="flex flex-col">
                <p className="pb-1 font-medium text-lg">Total Price*</p>
                <div className="flex gap-5 items-center bg-slate-300 p-2 rounded-md">
                  <input
                    required
                    placeholder="Price"
                    type="number"
                    className="bg-slate-300 focus:outline-none w-full"
                    {...register("price", {
                      required: "Price is required",
                    })}
                  />
                </div>
                <p className="text-red-500">{errors.price?.message}</p>
              </div>
              {/* Description */}
              <div className="col-span-2 md:col-span-3 flex flex-col">
                <p className="pb-1 font-medium text-lg">Description*</p>
                <div className="flex gap-5 items-center bg-slate-300 p-2 rounded-md">
                  <textarea
                    required
                    placeholder="Description"
                    className="bg-slate-300 focus:outline-none w-full h-24 resize-none"
                    {...register("description", {
                      required: "Description is required",
                      pattern: {
                        value: /^(?:(?!\d{9})[\s\S])*$/,
                        message: "No more than 8 consecutive digits allowed",
                      },
                      validate: (field) => {
                        return (
                          field.trim().length >= 20 ||
                          "Description must be at least 20 characters"
                        );
                      },
                    })}
                  />
                </div>
                <p className="text-red-500">{errors.description?.message}</p>
              </div>
              {/* images */}
              <div className="col-span-2 md:col-span-3 flex flex-col">
                <p className="pb-1 font-medium text-lg">Property Images*</p>
                <div className="flex gap-6">
                  {existingImages.map((image, index) => (
                    <div className="w-1/5 relative group" key={index}>
                      <img
                        className="w-full h-[80px]"
                        src={`${image.filePath}`}
                        alt={image.file_name}
                      />
                      <i
                        className="fa-solid fa-trash fa-lg absolute top-3 right-0 text-red-700 opacity-0 group-hover:opacity-100 transition-opacity duration-200 cursor-pointer"
                        onClick={() => {
                          removeExistingImage(index);
                        }}
                      ></i>
                    </div>
                  ))}
                </div>
                <input
                  type="file"
                  className="focus:outline-none mt-4"
                  multiple
                  {...register("property_images", {
                    validate: (files) => {
                      const totalFiles = existingImages.length + files.length;
                      return (
                        totalFiles < 6 || "You can upload a maximum of 5 files."
                      );
                    },
                  })}
                />
                <p className="text-red-500">
                  {errors.property_images?.message}
                </p>
              </div>
              {/* Documents */}
              <div className="col-span-2 flex flex-col gap-2 md:mt-8">
                <div className="mb-2">
                  <div className="flex items-center gap-3 ">
                    <p className="font-medium text-lg">Property Documents</p>
                    <p className="text-sm text-red-500">(*Max limit 4MB) </p>
                  </div>
                  <div className="flex gap-3 mt-2">
                    <a
                      href="https://www.ilovepdf.com/compress_pdf"
                      className="bg-slate-300 px-2 rounded-lg text-sm"
                      target="_blank"
                    >
                      Compress PDF{" "}
                    </a>
                    <a
                      href="https://www.docucompress.com/word/"
                      target="_blank"
                      className="bg-slate-300 px-2 rounded-lg text-sm"
                    >
                      Compress Doc{" "}
                    </a>
                  </div>
                </div>

                {/* Upload Documents */}
                <select
                  className="bg-slate-300 focus:outline-none py-2 rounded-lg"
                  ref={selectRef}
                  onChange={() => handleSelectDocType()}
                >
                  <option value="">Select an option</option>
                  <option value="lagan_receipt">लगान रसीद/Lagan Receipt</option>
                  <option value="sale_deed">सेल डीड/Sale Deed</option>
                  <option value="land_map">भूमि का नक्शा/Land Map</option>
                  <option value="land_revenue_record">
                    ज़मीन की जमाबंदी/Land Revenue Record
                  </option>
                  <option value="partition_deed">
                    पारिवारिक बंटवारा पत्र/Partition Deed
                  </option>
                  <option value="mutation">दाखिल ख़ारिज/Mutation</option>
                  <option value="record_of_rights">
                    खतियान/Record of Rights - RoR
                  </option>
                </select>
                {isDecTypeSelect !== "" ? (
                  <>
                    <label
                      htmlFor="inputField"
                      className={`w-fit bg-green-950 hover:bg-green-800 text-white font-medium py-2 px-5 rounded-lg text-center cursor-pointer`}
                    >
                      Upload Document
                    </label>
                    <input
                      type="file"
                      className="hidden"
                      id="inputField"
                      onChange={(e) =>
                        e.target.value
                          ? handleFileUpload(e.target.files[0])
                          : null
                      }
                    />
                  </>
                ) : (
                  <>
                    <label className="w-fit bg-green-950/50 py-2 px-5 rounded-lg text-white font-medium text-center">
                      Upload Document
                    </label>
                  </>
                )}

                {/* Display Documents */}
                <div className="">
                  {/* Lagan Receipt */}
                  {propertyDetails?.lagan_receipt && (
                    <div className="flex mt-4 gap-5 shadow-lg p-2 rounded-lg bg-slate-100">
                      <div className="w-[273px]">
                        <a
                          href={`${propertyDetails?.lagan_receipt.file_path}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="ps-3"
                        >
                          Lagan Receipt
                        </a>
                      </div>
                      <div className="w-[273px] ms-4">
                        <p>{propertyDetails?.lagan_receipt.file_name}</p>
                      </div>
                      <div className="ms-auto me-3">
                        <i
                          className="fa-solid fa-trash text-red-600 cursor-pointer"
                          onClick={() => removeExistingDoc("lagan_receipt")}
                        ></i>
                      </div>
                    </div>
                  )}

                  {/* Sale Deed */}
                  {propertyDetails.sale_deed && (
                    <div className="flex mt-4 gap-5 shadow-lg p-2 rounded-lg bg-slate-100">
                      <div className="w-[273px]">
                        <a
                          href={`${propertyDetails.sale_deed.file_path}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="ps-3"
                        >
                          Sale Deed
                        </a>
                      </div>
                      <div className="w-[273px] ms-4">
                        <p>{propertyDetails.sale_deed.file_name}</p>
                      </div>
                      <div className="ms-auto me-3">
                        <i
                          className="fa-solid fa-trash text-red-600 cursor-pointer"
                          onClick={() => removeExistingDoc("sale_deed")}
                        ></i>
                      </div>
                    </div>
                  )}

                  {/* Land Revenue Record */}
                  {propertyDetails.land_revenue_record && (
                    <div className="flex mt-4 gap-5 shadow-lg p-2 rounded-lg bg-slate-100">
                      <div className="w-[273px]">
                        <a
                          href={`${propertyDetails.land_revenue_record.file_path}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="ps-3"
                        >
                          Land Revenue Record
                        </a>
                      </div>
                      <div className="w-[273px] ms-4">
                        <p>{propertyDetails.land_revenue_record.file_name}</p>
                      </div>
                      <div className="ms-auto me-3">
                        <i
                          className="fa-solid fa-trash text-red-600 cursor-pointer"
                          onClick={() =>
                            removeExistingDoc("land_revenue_record")
                          }
                        ></i>
                      </div>
                    </div>
                  )}

                  {/* Land Map */}
                  {propertyDetails.land_map && (
                    <div className="flex mt-4 gap-5 shadow-lg p-2 rounded-lg bg-slate-100">
                      <div className="w-[273px]">
                        <a
                          href={`${propertyDetails.land_map.file_path}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="ps-3"
                        >
                          Land Map
                        </a>
                      </div>
                      <div className="w-[273px] ms-4">
                        <p>{propertyDetails.land_map.file_name}</p>
                      </div>
                      <div className="ms-auto me-3">
                        <i
                          className="fa-solid fa-trash text-red-600 cursor-pointer"
                          onClick={() => removeExistingDoc("land_map")}
                        ></i>
                      </div>
                    </div>
                  )}

                  {/* Partition Deed */}
                  {propertyDetails.partition_deed && (
                    <div className="flex mt-4 gap-5 shadow-lg p-2 rounded-lg bg-slate-100">
                      <div className="w-[273px]">
                        <a
                          href={`${propertyDetails.partition_deed.file_path}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="ps-3"
                        >
                          Partition Deed
                        </a>
                      </div>
                      <div className="w-[273px] ms-4">
                        <p>{propertyDetails.partition_deed.file_name}</p>
                      </div>
                      <div className="ms-auto me-3">
                        <i
                          className="fa-solid fa-trash text-red-600 cursor-pointer"
                          onClick={() => removeExistingDoc("partition_deed")}
                        ></i>
                      </div>
                    </div>
                  )}

                  {/* Mutation */}
                  {propertyDetails.mutation && (
                    <div className="flex mt-4 gap-5 shadow-lg p-2 rounded-lg bg-slate-100">
                      <div className="w-[273px]">
                        <a
                          href={`${propertyDetails.mutation.file_path}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="ps-3"
                        >
                          Mutation
                        </a>
                      </div>
                      <div className="w-[273px] ms-4">
                        <p>{propertyDetails.mutation.file_name}</p>
                      </div>
                      <div className="ms-auto me-3">
                        <i
                          className="fa-solid fa-trash text-red-600 cursor-pointer"
                          onClick={() => removeExistingDoc("mutation")}
                        ></i>
                      </div>
                    </div>
                  )}

                  {/* Record of Rights */}
                  {propertyDetails.record_of_rights && (
                    <div className="flex mt-4 gap-5 shadow-lg p-2 rounded-lg bg-slate-100">
                      <div className="w-[273px]">
                        <a
                          href={`${propertyDetails.record_of_rights.file_path}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="ps-3"
                        >
                          Record of Rights
                        </a>
                      </div>
                      <div className="w-[273px] ms-4">
                        <p>{propertyDetails.record_of_rights.file_name}</p>
                      </div>
                      <div className="ms-auto me-3">
                        <i
                          className="fa-solid fa-trash text-red-600 cursor-pointer"
                          onClick={() => removeExistingDoc("record_of_rights")}
                        ></i>
                      </div>
                    </div>
                  )}
                </div>

                <div className="">
                  {fileUpload.length > 0
                    ? fileUpload.map((item, index) => (
                        <div
                          key={index}
                          className="flex mt-4 gap-5 shadow-lg p-2 rounded-lg bg-slate-100"
                        >
                          <div className="w-[273px]">
                            <p className="">{item.file_type}</p>
                          </div>
                          <div className="w-[273px] ms-4">
                            <p>{item.file.name}</p>
                          </div>
                          <div className="ms-auto me-3">
                            <i
                              className="fa-solid fa-trash text-red-600 cursor-pointer"
                              onClick={() => removeFileDate(index)}
                            ></i>
                          </div>
                        </div>
                      ))
                    : null}
                </div>
              </div>
            </div>
            {/* Submit Button */}
            <div className="flex gap-5 items-center p-2 rounded-md mt-6 justify-center">
              {!loader ? (
                <>
                  <button className="rounded-2xl py-2 px-8 text-white font-bold bg-yellow-500 hover:bg-yellow-600">
                    Submit
                  </button>
                </>
              ) : (
                <>
                  <div className="w-8 h-8 border-4 border-green-500 border-t-transparent border-solid rounded-full animate-spin"></div>
                </>
              )}
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
export default EditProperty;
