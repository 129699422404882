const UnitsTable = ({
  page,
  setPage,
  data,
  deleteUnit,
  handleEditUnitModal,
}) => {
  console.log(data);
  return (
    <>
      <div className="overflow-auto md:w-2/4 md:overflow-none">
        <table className="table-auto min-w-max w-full mt-4 border shadow-sm border-slate-300">
          <thead className="bg-[#F9FAFC] text-[#5a5a5b] border-b border-slate-300 text-left">
            <tr>
              <th className="p-3 font-medium text-sm">S.No</th>
              <th className="p-3 font-medium text-sm">Unit</th>
              <th className="p-3 font-medium text-sm">Action</th>
            </tr>
          </thead>
          <tbody>
            {data.length > 0 ? (
              data.slice(page * 15 - 15, page * 15).map((data, index) => (
                <tr
                  key={data._id}
                  className="bg-white border-b border-slate-300 align-middle h-auto"
                >
                  <td className="p-3 text-xs md:text-sm font-medium">
                    {(page - 1) * 15 + index + 1}
                  </td>
                  <td className="p-3 text-xs md:text-sm font-medium">
                    {data.unit}
                  </td>
                  <td className="p-3 text-xs md:text-sm">
                    <i
                      className="fa-solid fa-pen text-green-500 cursor-pointer mx-2"
                      title="Edit Unit"
                      onClick={() => handleEditUnitModal(data._id, data.unit)}
                    ></i>
                    <i
                      className="fa-solid fa-trash text-red-500 cursor-pointer mx-2"
                      title="Delete Unit"
                      onClick={() => deleteUnit(data._id)}
                    ></i>
                  </td>
                </tr>
              ))
            ) : (
              <tr className="bg-white text-center">
                <td colSpan={3} className="p-3 text-xs md:text-sm">
                  No Units Found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {data.length > 0 && (
        <div className="md:w-2/4 flex items-center justify-center mt-7">
          <button
            className="px-2 py-1 mx-1 text-sm bg-black text-white rounded-md"
            disabled={page < 2}
            onClick={() => setPage(page - 1)}
          >
            Prev
          </button>
          <span>
            {page}/{Math.ceil(data.length / 15)}
          </span>
          <button
            className="px-2 py-1 mx-1 text-sm bg-black text-white rounded-md"
            disabled={page >= Math.ceil(data.length / 15)}
            onClick={() => setPage(page + 1)}
          >
            Next
          </button>
        </div>
      )}
    </>
  );
};

export default UnitsTable;
