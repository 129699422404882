import { Button, Modal } from "flowbite-react";

export const PaymentConfirm = ({ open, onClose, viewProperty }) => {
  if (!open) return null;

  const acceptCondition = () => {
    viewProperty();
    onClose();
  };

  return (
    <Modal
      show={open}
      onClose={onClose}
      className="w-11/12 sm:w-10/12 md:w-6/12 !top-1/4 !left-1/2 -translate-x-1/2 z-10"
    >
      <div className="flex justify-center items-center">
        <div className="w-full h-full bg-white rounded-lg shadow-lg text-center p-3 border-4 border-green-950">
          <i class="fa-solid fa-circle-check text-5xl text-green-700 mb-2"></i>
          <h3 className="text-2xl font-semibold">Confirmation</h3>
          <Modal.Body className="px-0">
            <p className="font-medium">
              Are you sure you want to access the seller/consultant's contact
              information and property documents.
            </p>
          </Modal.Body>
          <Modal.Footer className="justify-end gap-x-2 py-0">
            <Button onClick={acceptCondition} className="bg-green-950">
              Accept
            </Button>
            <Button className="bg-red-500" onClick={onClose}>
              Decline
            </Button>
          </Modal.Footer>
        </div>
      </div>
    </Modal>
  );
};
